import React, { useEffect, useState } from 'react'
import { GA4ProductType, GA4PageNameType } from '@/ga4types'
import ChatNotification from './ChatNotification'
import TravelChatBotModal from './TravelChatBotModal'

const PENNY_PUSH_MESSAGE_DELAY = 1000

export type ChatBotPopOverVal = 'CLOSE' | 'OPEN' | 'ALREADY_OPENED'

export default function TravelChatBot({
  enableChatBot,
  openPenny,
  askPrompt,
  pageName,
  productType
}: {
  enableChatBot: boolean
  openPenny?: string
  askPrompt?: string
  pageName: GA4PageNameType
  productType?: GA4ProductType
}) {
  const [openChatBotPopOver, setOpenChatBotPopOver] =
    useState<ChatBotPopOverVal>('CLOSE')
  const [isChatBotDialogOpened, setChatBotDialogOpeningState] = useState(false)

  useEffect(() => {
    if (
      openPenny === 'y' &&
      ((askPrompt && askPrompt?.length <= 140) || !askPrompt)
    ) {
      setChatBotDialogOpeningState(true)
    }
  }, [openPenny, askPrompt, setChatBotDialogOpeningState])

  useEffect(() => {
    setTimeout(
      /* istanbul ignore next */ () => {
        if (openChatBotPopOver === 'CLOSE') {
          setOpenChatBotPopOver('OPEN')
        }
      },
      PENNY_PUSH_MESSAGE_DELAY
    )
  }, [setOpenChatBotPopOver, openChatBotPopOver])

  return (
    <>
      {enableChatBot && (
        <TravelChatBotModal
          isChatBotDialogOpened={isChatBotDialogOpened}
          initialPrompt={askPrompt ?? ''}
          onModalClose={
            /* istanbul ignore next */ () => {
              setChatBotDialogOpeningState(false)
            }
          }
          pageName={pageName}
          productType={productType}
        />
      )}
      {!isChatBotDialogOpened && enableChatBot && (
        <ChatNotification
          isChatBotDialogOpened={isChatBotDialogOpened}
          openChatBotPopOver={openChatBotPopOver}
          setChatBotDialogOpeningState={setChatBotDialogOpeningState}
          setOpenChatBotPopOver={setOpenChatBotPopOver}
          pageName={pageName}
          productType={productType}
        />
      )}
    </>
  )
}
