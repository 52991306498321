import { update } from 'ramda'
import { UseFormSetValue } from 'react-hook-form'
import { getPlaceData, buildShopSearchForm } from '@pcln/build-shop-search-form'
import { format } from 'date-fns/format'
import { toDate } from 'date-fns/toDate'
import { min } from 'date-fns/min'
import { max } from 'date-fns/max'
import { parseISO } from 'date-fns/parseISO'
import { HOTEL_FORM_STATE_TYPE } from '../../types'
import { MULTI_HOTEL_FORM_STATE_TYPE, HOTEL_DETAILS } from './types'

const HOTEL_IDS = [0, 1, 2, 3, 4]

const defaultTravelers = {
  adults: 2,
  chilren: 0,
  childrenAges: {},
  rooms: 1
}
export const getInitialState = (
  singleHotelFormDetails: Partial<HOTEL_FORM_STATE_TYPE> | null | undefined
) => {
  const {
    endDate = '',
    startDate = '',
    endLocation = null,
    travelers = defaultTravelers
  } = singleHotelFormDetails || {}
  return {
    hotels: [
      {
        endLocation,
        startDate,
        endDate,
        id: 0
      },
      {
        startDate: '',
        endDate: '',
        endLocation: null,
        id: 1
      }
    ],
    travelers
  }
}

export const fields = ['hotels', 'travelers'] as const

export const setHotels = (
  hotels: HOTEL_DETAILS[],
  data: Partial<HOTEL_DETAILS>,
  setValue: UseFormSetValue<MULTI_HOTEL_FORM_STATE_TYPE>,
  index = 0
): void => {
  const hotelToAdd = { ...hotels[index], ...data }
  const result = update(index, hotelToAdd, hotels)
  setValue('hotels', result, { shouldDirty: true })
}

const getFirstUnusedId = (hotels: HOTEL_DETAILS[]) => {
  const unusedIds = HOTEL_IDS.filter(id => !hotels.find(h => h.id === id))
  return unusedIds[0]
}

export const addDestination = (
  hotels: HOTEL_DETAILS[],
  setValue: UseFormSetValue<MULTI_HOTEL_FORM_STATE_TYPE>
) => {
  if (hotels.length > 4) return
  const hotelToAdd = {
    endLocation: null,
    startDate: '',
    endDate: '',
    id: getFirstUnusedId(hotels)
  }
  const result = [...hotels, hotelToAdd]
  setValue('hotels', result, { shouldDirty: true })
}

export const removeDestination = (
  hotels: HOTEL_DETAILS[],
  setValue: UseFormSetValue<MULTI_HOTEL_FORM_STATE_TYPE>,
  index: number
) => {
  const result = hotels.filter((_, hotelIndex) => hotelIndex !== index)
  setValue('hotels', result, { shouldDirty: true })
}

const buildHotels = (hotels: HOTEL_DETAILS[], roomCount: number) => {
  return hotels.map((hotel, index) => ({
    type: 'STAY',
    productId: 5,
    index,
    query: {
      travelStartDate: {
        value: format(toDate(parseISO(hotel.startDate)), 'yyyy-MM-dd')
      },
      travelEndDate: {
        value: format(toDate(parseISO(hotel.endDate)), 'yyyy-MM-dd')
      },
      totalNumRooms: roomCount,
      destination: getPlaceData(hotel.endLocation)
    }
  }))
}

const getEarliestDate = (hotels: HOTEL_DETAILS[]) => {
  const dates = hotels.map(h => toDate(parseISO(h.startDate)))
  return format(min(dates), 'MM/dd/yyyy')
}

const getLatestDate = (hotels: HOTEL_DETAILS[]) => {
  const dates = hotels.map(h => toDate(parseISO(h.endDate)))
  return format(max(dates), 'MM/dd/yyyy')
}
export const handleMultiHotelSubmit = (data: MULTI_HOTEL_FORM_STATE_TYPE) => {
  const { hotels, travelers } = data
  const { children, childrenAges, rooms, adults } = travelers
  const mutableChildren: Record<string, number> = {}

  if (
    children > 0 &&
    typeof childrenAges === 'object' &&
    childrenAges !== null
  ) {
    Object.keys(childrenAges).forEach((key, i) => {
      const index = i + 1
      const newKey = `child${index}`
      const age = parseInt(childrenAges[key], 10)
      if (!Number.isNaN(age)) {
        mutableChildren[newKey] = age
      }
    })
  }

  hotels.forEach(hotel => {
    if (
      hotel?.endLocation?.type === 'POI' &&
      hotel?.endLocation?.cityID &&
      hotel?.endLocation?.cityName
    ) {
      // eslint-disable-next-line no-param-reassign
      hotel.endLocation.type = 'CITY'
    }
  })

  const searchRequest = {
    rooms,
    adultCount: adults,
    childCount: children,
    children: mutableChildren,
    pivotComponentKey: 0,
    components: buildHotels(hotels, rooms),
    arrivalLocation: hotels[0].endLocation,
    departureLocation: hotels[0].endLocation,
    departureDate: getEarliestDate(hotels),
    returnDate: getLatestDate(hotels)
  }

  const url = '/shop/search/itinerary'
  const form = buildShopSearchForm(url, searchRequest)
  form.submit()
}
