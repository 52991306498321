import analytics from '@/shared-utils/analytics'
import hasGraphErrors from '@/shared-utils/graph-utils'
import {
  AbandonedSelectionApiResponse,
  SearchProductList
} from '@/components/TripActivity/types'
import isAbortSignalTimeoutSupported from '@/shared-utils/is-abortSignal-supported'
import {
  abandonedHotelQuery,
  abandonedRentalCarQuery,
  abandonedFlightQuery
} from '@/constants/queryFragments'
import { GraphResponse } from '@/types'
import config from 'isomorphic-config'
import { jsonContentTypeHeader } from '../server/constants'

type AbandonedSelectionsQueryResultSignedOut<T> = {
  readonly getAbandonedItemsByCguid: T
}

type AbandonedSelectionsResult = ReadonlyArray<AbandonedSelectionApiResponse>

type AbandonedSelectionsGraphResponse = GraphResponse<
  AbandonedSelectionsQueryResultSignedOut<AbandonedSelectionsResult>
>

function hasRedeemablePayload(
  payload: unknown
): payload is AbandonedSelectionsGraphResponse {
  if (payload && payload instanceof Object) {
    if ('data' in payload && payload.data && typeof payload.data === 'object') {
      return (
        ('getAbandonedItemsByCguid' in payload.data &&
          Array.isArray(payload.data.getAbandonedItemsByCguid)) ||
        ('getAbandonedItemsByAuthTokenAndCguid' in payload.data &&
          Array.isArray(payload.data.getAbandonedItemsByAuthTokenAndCguid))
      )
    }
  }
  return false
}

function isValidGraphQLResponse(
  payload: unknown
): payload is AbandonedSelectionsGraphResponse {
  if (payload && payload instanceof Object) {
    if ('errors' in payload && Array.isArray(payload.errors)) {
      return true
    }
    if (
      'data' in payload &&
      payload.data &&
      typeof payload.data === 'object' &&
      'getAbandonedItemsByAuthTokenAndCguid' in payload.data &&
      (payload.data.getAbandonedItemsByAuthTokenAndCguid === null ||
        Array.isArray(payload.data.getAbandonedItemsByAuthTokenAndCguid))
    ) {
      return true
    }
    if (
      'data' in payload &&
      payload.data &&
      typeof payload.data === 'object' &&
      'getAbandonedItemsByCguid' in payload.data &&
      (payload.data.getAbandonedItemsByCguid === null ||
        Array.isArray(payload.data.getAbandonedItemsByCguid))
    ) {
      return true
    }
  }
  return false
}
const { url, timeout } = config.client['pcln-graph']

async function fetchAbandonedSelections(
  cguid: string,
  appName: string,
  appVersion: string,
  productType: SearchProductList,
  shouldIncludeAbandonedFlights: boolean
) {
  const options = {
    method: 'POST',
    signal: isAbortSignalTimeoutSupported()
      ? AbortSignal.timeout(timeout)
      : undefined,
    headers: {
      ...jsonContentTypeHeader,
      'apollographql-client-name': appName,
      'apollographql-client-version': appVersion
    },
    body: JSON.stringify({
      query: `
                  query AbandonedSelections($cguid: String!) {
                      getAbandonedItemsByCguid(cguid: $cguid) {
                      ${productType.includes('STAY') ? abandonedHotelQuery : ''}
                      ${
                        productType.includes('DRIVE')
                          ? abandonedRentalCarQuery
                          : ''
                      }
                      ${
                        productType.includes('FLY') &&
                        shouldIncludeAbandonedFlights
                          ? abandonedFlightQuery
                          : ''
                      }
                    }
                  }
                `,
      variables: {
        cguid
      }
    })
  }

  const requestUrl = `${url}?gqlOp=getAbandonedItemsByCguid`

  try {
    const response = await fetch(requestUrl, options)
    const { status, url: urlValue } = response
    if (!response.ok) {
      analytics.logError({
        message: 'The getAbandonedItems graph query response status is > 299',
        url: urlValue,
        status
      })
    } else {
      const payload = await response.json()
      if (isValidGraphQLResponse(payload)) {
        const payloadHasGraphErrors =
          hasGraphErrors<AbandonedSelectionsResult>(payload)
        const payloadHasRedeemableData = hasRedeemablePayload(payload)
        if (payloadHasGraphErrors) {
          const firstErrorMessage = payload.errors?.[0]?.message
          const errorMessage = firstErrorMessage || 'Unknown error'
          const errorObjectAsString = JSON.stringify(payload.errors)
          const message =
            'The getAbandonedItems graph query response has returned errors.'
          analytics.logError({
            message,
            errorMessage,
            url: requestUrl,
            appName,
            appVersion,
            errorObjectAsString,
            hasRedeemablePayload: payloadHasRedeemableData
          })
        }
        if (
          payload.data &&
          (!payloadHasGraphErrors || payloadHasRedeemableData)
        ) {
          if ('getAbandonedItemsByCguid' in payload.data) {
            return payload.data.getAbandonedItemsByCguid
          }
        }
      } else {
        analytics.logError({
          message: `The getAbandonedItems graph query response is not as per contract`,
          payload: JSON.stringify(payload)
        })
      }
    }
    return []
  } catch (error) {
    const errorMessage =
      error && error instanceof Error
        ? `${error.message} Error Name: ${error.name}`
        : 'Unknown error'
    analytics.logError({
      message: 'Failed to call the getAbandonedItems graph query.',
      errorMessage,
      error: JSON.stringify(error)
    })
    return []
  }
}

export default fetchAbandonedSelections
