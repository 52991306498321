import { ISilhouetteMarketingModuleType } from '@pcln/contentful-components'
import analytics from './analytics'

export default function mapSilhouetteContentfulData(
  data: ISilhouetteMarketingModuleType,
  analyticsCategory: string
) {
  const {
    heading,
    subheading,
    overline,
    primaryCallToAction,
    media,
    silhouetteMirror
  } = data
  return {
    heading,
    subheading,
    overline,
    callToAction: {
      text: primaryCallToAction?.label,
      url: primaryCallToAction?.url,
      ariaLabel: primaryCallToAction?.ariaLabel
    },
    onClick: () => {
      analytics.fireEvent({
        action: primaryCallToAction?.analyticsTracking?.action,
        category: analyticsCategory,
        label: primaryCallToAction?.analyticsTracking?.label
      })
    },
    imageUrl: media?.file?.url && `${media?.file.url}?w=748&h=374&fm=webp&q=80`,
    silhouetteMirror: silhouetteMirror ?? 'left'
  }
}
