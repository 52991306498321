import useRecentSearchCard from '@/hooks/useRecentSearchCard'
import useBootstrapData from '@/hooks/useBootstrapData'
import useSeti from '@/hooks/useSeti'
import usePriceWatches from '@/hooks/usePriceWatches'
import useAbandonedSelections from './useAbandonedSelections'
import type { AbandonedSelection, SearchProductList } from '../types'
import { computePriceWatchAndRecentSearches } from '../utils'

function useTripActivityData(
  productType: SearchProductList,
  abandonedSelectionData?: AbandonedSelection | null,
  shouldIncludeAbandonedFlights = false
) {
  const {
    webstats: { clientGUID },
    appName,
    appVersion,
    signInStatus: { signedIn },
    customer: { emailAddress }
  } = useBootstrapData()

  const isPriceWatchSeti = useSeti('HP_PUWYLO_PRICEWATCH', false) === 'VARIANT'

  const abandonedSelectionFetched = useAbandonedSelections(
    clientGUID,
    appName,
    appVersion,
    signedIn,
    productType,
    shouldIncludeAbandonedFlights
  )

  // when signed in, abandonedSelectionData is passed in as a prop from the parent
  // and we will get the data from useTripGroupings
  const abandonedSelection = abandonedSelectionFetched || abandonedSelectionData

  const recentSearchData = useRecentSearchCard(
    clientGUID,
    { appName, appVersion },
    productType,
    signedIn
  )

  const priceWatches = usePriceWatches(
    emailAddress,
    appName,
    appVersion,
    isPriceWatchSeti
  )
  return {
    abandonedSelection,
    atMostFiveRecentSearchCards: isPriceWatchSeti
      ? computePriceWatchAndRecentSearches(
          priceWatches,
          recentSearchData
        ).slice(0, 5)
      : recentSearchData.slice(0, 5)
  }
}

export default useTripActivityData
