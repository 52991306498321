import { ProductType } from '@/types'
import { GA4PageNameType } from '@/ga4types'
import analytics from '@/shared-utils/analytics'

export default function logSearchFormAnalytics(
  cguid: string,
  pageName: GA4PageNameType,
  productType: ProductType,
  isMobile: boolean
) {
  analytics.log({
    message: 'User submitted search form',
    cguid,
    searchForm: productType,
    pageName,
    isMobile
  })
}
