import React, { useState } from 'react'
import { LinksData } from '../types'
import {
  ExpandableWrapper,
  ExpandableLink,
  ExpandableHeader,
  ExpandableLinkText,
  ExpandablePrice,
  ExpandableContent,
  HeaderLink
} from './styled'

interface ExpandableLinksBlockProps {
  linksData: LinksData
}

function ExpandableLinksBlock({ linksData }: ExpandableLinksBlockProps) {
  const { mainLink, topLevelLinks, collapsedLinks } = linksData

  const [isOpen, setIsOpen] = useState(false)

  const clickHandler = () => {
    setIsOpen(!isOpen)
  }

  return (
    <ExpandableWrapper $isOpen={isOpen} onClick={clickHandler}>
      <ExpandableHeader $hasLinks={!!collapsedLinks} $isOpen={isOpen}>
        {mainLink.text}
      </ExpandableHeader>
      {topLevelLinks &&
        topLevelLinks.map(linkInfo => (
          <HeaderLink
            key={`${mainLink.text}_${linkInfo.text}`}
            href={linkInfo.href}
            target="_blank"
          >
            {linkInfo.text}
          </HeaderLink>
        ))}
      <ExpandableContent $isOpen={isOpen}>
        {collapsedLinks &&
          collapsedLinks.map((linkInfo, index) => (
            <ExpandableLink
              href={linkInfo.href}
              key={`${linkInfo.text}-${index + 1}`}
              target="_blank"
            >
              <ExpandableLinkText>{linkInfo.text}</ExpandableLinkText>
              <ExpandablePrice>{linkInfo.price}</ExpandablePrice>
            </ExpandableLink>
          ))}
      </ExpandableContent>
    </ExpandableWrapper>
  )
}

export default ExpandableLinksBlock
