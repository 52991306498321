import React from 'react'
import { Box, Hide } from 'pcln-design-system'
import ExtendStay from '@/components/ExtendStay'
import useBootstrapData from '@/hooks/useBootstrapData'
import useSeti from '@/hooks/useSeti'
import TripGrouping from '@/components/TripGrouping/TripGrouping'
import TripActivity from '@/components/TripActivity/TripActivity'
import { ANALYTICS_CATEGORY_HOME } from '@/constants/analytics'
import useTripBookings from '@/hooks/useTripBookings'

type TripDisplayOrderProps = {
  isLandingPage?: boolean
}

export default function TripDisplayOrder({
  isLandingPage = false
}: TripDisplayOrderProps) {
  const {
    webstats: { clientGUID },
    appName,
    appVersion,
    signInStatus: { signedIn },
    customer: { emailAddress }
  } = useBootstrapData()

  const shouldIncludeAbandonedFlights =
    useSeti('HP_PUWYLO_FLIGHTSELECTIONS') === 'VARIANT'

  const {
    extendStayBooking,
    tripGroupingBooking,
    tripGroupingAbandonedSelection,
    defaultAbandonedSelection
  } = useTripBookings(
    clientGUID,
    appName,
    appVersion,
    emailAddress,
    signedIn,
    shouldIncludeAbandonedFlights
  )

  if (signedIn) {
    if (extendStayBooking) {
      return (
        <Box mb={[0, null, 1]}>
          <Hide md mb={4}>
            <ExtendStay {...extendStayBooking} />
          </Hide>
          <TripActivity
            productType={['STAY', 'FLY', 'DRIVE']}
            analyticsCategory={ANALYTICS_CATEGORY_HOME}
            shouldUseSmallerHeading
          />
        </Box>
      )
    }
    return (
      <Box mb={[0, null, 1]}>
        <TripGrouping
          isLandingPage={isLandingPage}
          tripGroupingBooking={tripGroupingBooking}
          tripGroupingAbandonedSelection={tripGroupingAbandonedSelection}
          defaultAbandonedSelection={defaultAbandonedSelection}
        />
      </Box>
    )
  }

  return (
    <Box mb={[0, null, 1]}>
      <TripActivity
        productType={['STAY', 'FLY', 'DRIVE']}
        analyticsCategory={ANALYTICS_CATEGORY_HOME}
        shouldUseSmallerHeading={false}
      />
    </Box>
  )
}
