import * as yup from 'yup'
import { formatDate } from '@/shared-utils/date-helper'
import { LOCATION_SEARCH_TYPE } from '@/types'
import { DRIVE, FLY, STAY } from '../constants'
import { CAR_FORM_STATE_TYPE } from '../types'

export const CHILD_AGE_REQUIRED = "Please enter children's age"
const DATE_RANGE_ERROR =
  'Please make sure the drop-off date is the same or later than the pick-up date'
export const DROPOFF_LOCATION_REQUIRED = 'Please select a drop-off location'
export const INVALID_DATE = 'Please select a valid date'
export const INVALID_DATES = 'Please select valid dates'
export const HOTEL_LOCATION_REQUIRED = 'Please enter a valid location'
export const PICKUP_LOCATION_REQUIRED = 'Please select a pick-up location'
const MAXIMUM_BUNDLE_GUEST_COUNT = 8
const BUNDLE_MAX_CAPACITY_ERROR = `Bundles accommodate up to ${MAXIMUM_BUNDLE_GUEST_COUNT} people. You may need an additional bundle for your group`

const DISALLOW_INFANT_INTL_FLIGHT =
  'Sorry, we do not offer lap infant seating on international flights'

const intlLocation = (location: LOCATION_SEARCH_TYPE | null) =>
  location &&
  typeof location.countryCode === 'string' &&
  location.countryCode.toUpperCase() !== 'US'

const isInfantFound = (childrenAges: number[]) =>
  Array.isArray(childrenAges) && childrenAges.some(age => Number(age) === 0)

const isLocationWithInfant = (
  locationObject: LOCATION_SEARCH_TYPE | null,
  childrenAges: number[]
) => intlLocation(locationObject) && isInfantFound(childrenAges)

function handleInfantTravel(this: yup.TestContext) {
  const { childrenCount, childrenAges, endLocation, startLocation, tripType } =
    this.parent

  const validChildrenAges = childrenAges.filter((value: number) => value !== -1)

  if (
    tripType.includes(FLY) &&
    (isLocationWithInfant(startLocation, childrenAges) ||
      isLocationWithInfant(endLocation, childrenAges))
  ) {
    return this.createError({
      message: DISALLOW_INFANT_INTL_FLIGHT,
      path: this.path
    })
  }

  if (
    (tripType.includes(STAY) || tripType.includes(FLY)) &&
    childrenCount > 0 &&
    childrenCount !== validChildrenAges.length
  ) {
    return this.createError({
      message: CHILD_AGE_REQUIRED,
      path: this.path
    })
  }

  return true
}

function isWithinDateRange(this: yup.TestContext) {
  const { endDate, startDate } = this.parent
  const formattedStartDate = formatDate(startDate)
  const formattedEndDate = formatDate(endDate)

  return new Date(formattedStartDate) <= new Date(formattedEndDate)
}

function isWithinRoomCapacity(this: yup.TestContext) {
  const { adultCount, childrenCount } = this.parent as CAR_FORM_STATE_TYPE
  const travelerCount = adultCount + childrenCount

  return travelerCount <= MAXIMUM_BUNDLE_GUEST_COUNT
}

const searchFormSchema = yup.object().shape({
  childrenAges: yup.array().test({
    name: 'childrenAges',
    test: handleInfantTravel
  }),
  endDate: yup.string().when('tripType', {
    is: (tripType: string) => tripType === DRIVE,
    then: schema =>
      schema.required(INVALID_DATE).when('startDate', {
        is: (startDate: string) => startDate !== '',
        then: nestedSchema =>
          nestedSchema.test({
            message: DATE_RANGE_ERROR,
            name: 'endDate',
            test: isWithinDateRange
          }),
        otherwise: nestedSchema => nestedSchema.nullable()
      }),
    otherwise: schema => schema.nullable()
  }),
  endLocation: yup
    .object()
    .nullable()
    .when('oneWay', {
      is: true,
      then: schema => schema.nullable().required(DROPOFF_LOCATION_REQUIRED)
    })
    .when('tripType', {
      is: (tripType: string) => tripType.includes(STAY),
      then: schema => schema.nullable().required(HOTEL_LOCATION_REQUIRED)
    })
    .when('tripType', {
      is: (tripType: string) => tripType.includes(FLY),
      then: schema => schema.nullable().required(HOTEL_LOCATION_REQUIRED)
    }),
  hotelEndDate: yup
    .string()
    .nullable()
    .when('tripType', {
      is: (tripType: string) => tripType.includes(STAY),
      then: schema => schema.required(INVALID_DATES)
    })
    .when('tripType', {
      is: (tripType: string) => tripType.includes(FLY),
      then: schema => schema.required(INVALID_DATES)
    }),
  hotelStartDate: yup
    .string()
    .nullable()
    .when('tripType', {
      is: (tripType: string) => tripType.includes(STAY),
      then: schema => schema.required(INVALID_DATES)
    })
    .when('tripType', {
      is: (tripType: string) => tripType.includes(FLY),
      then: schema => schema.required(INVALID_DATES)
    }),
  roomCount: yup
    .number()
    .nullable()
    .when('tripType', {
      is: (tripType: string) => tripType.includes(STAY),
      then: schema =>
        schema.test({
          message: BUNDLE_MAX_CAPACITY_ERROR,
          name: 'roomCount',
          test: isWithinRoomCapacity
        })
    }),
  startDate: yup
    .string()
    .nullable()
    .when('tripType', {
      is: (tripType: string) => tripType === DRIVE,
      then: schema => schema.required(INVALID_DATE)
    }),
  startLocation: yup
    .object()
    .nullable()
    .when('tripType', {
      is: (tripType: string) => tripType === DRIVE,
      then: schema => schema.nullable().required(PICKUP_LOCATION_REQUIRED)
    })
    .when('tripType', {
      is: (tripType: string) => tripType.includes(FLY),
      then: schema => schema.nullable().required(HOTEL_LOCATION_REQUIRED)
    })
})

export default searchFormSchema
