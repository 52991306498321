import React, { SyntheticEvent, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { Box, Flex, Text, Button } from 'pcln-design-system'
import styled from 'styled-components'
import { Calendar } from '@pcln/date-picker'
import { Controller, useForm } from 'react-hook-form'
import { getHotelDateRange } from '@/shared-utils/date-helper'
import SearchFormButton from '@/components/SearchFormButton'
import TypeAhead from '@/components/TypeAhead'
import { PopoverContainer } from '@/components/Popover'
import ShadowEffect from '@/components/ShadowEffect'
import CalendarWrapperBox from '@/components/CalenderWrapperBox.styled'
import TravelerSelection from '@pcln/traveler-selection'
import DateField from '@/components/DateField'
import { VP_DISCLAIMER } from '@/components/TravelerSelection/constants'
import useTabGroup from '@/hooks/useTabGroup'
import { LOCATION_SEARCH_TYPE } from '@/types'
import { yupResolver } from '@hookform/resolvers/yup'
import useBootstrapData from '@/hooks/useBootstrapData'
import useIsIPad from '@/hooks/useIsIPad'
import logSearchFormAnalytics from '@/shared-utils/log-search-form-analytics'
import { CALENDAR_POPOVER_SELECTOR } from '../../constants'
import {
  fields,
  getInitialState,
  setHotels,
  addDestination,
  removeDestination,
  handleMultiHotelSubmit
} from './utils'
import { searchFormSchema } from './validation'

import { HOTEL_FORM_STATE_TYPE, TravelerSelectionOnChange } from '../../types'

import { MULTI_HOTEL_FORM_STATE_TYPE } from './types'

type MultiHotelFormType = {
  firstHotel?: Partial<HOTEL_FORM_STATE_TYPE> | null
}

const StyledText = styled(Text)`
  padding-top: 8px;
  padding-bottom: 8px;
`

const StyledButton = styled(Button)`
  padding: 6px 12px;
`

function MultiHotelForm({ firstHotel }: MultiHotelFormType) {
  const [isHotelCalendarOpen, setIsHotelCalendarOpen] = useState<boolean[]>([
    false,
    false,
    false,
    false,
    false
  ])
  const { registerTabElement } = useTabGroup()
  const {
    isMobile,
    webstats: { clientGUID: cguid }
  } = useBootstrapData()
  const isIPad = useIsIPad()
  const { pathname } = useRouter()
  const isHomepage = pathname === '/'

  const initialValues = getInitialState(firstHotel)

  const {
    clearErrors,
    register,
    unregister,
    setValue,
    handleSubmit,
    watch,
    trigger,
    control,
    formState: { errors }
  } = useForm<MULTI_HOTEL_FORM_STATE_TYPE>({
    defaultValues: initialValues,
    mode: 'onSubmit',
    resolver: yupResolver(searchFormSchema),
    reValidateMode: 'onChange'
  })

  useEffect(() => {
    fields.forEach(name => {
      register(name)
    })
    return () => {
      fields.forEach(name => {
        unregister(name)
      })
    }
  }, [register, unregister])

  const { hotels: watchHotels, travelers: watchTravelers } = watch()

  const maxDaysToSelect = 30

  const inputFormMargin = 3

  const searchBtnMargin = 1

  const typeaheadWidth = [1, 1, 1 / 2]

  const calendarWidth = [1, 1, 1 / 2]

  const roomSelectionWidth = [1, null, 1 / 2, null, 1 / 2]

  const searchButtonWidth = [1, null, 1 / 2, null, 1 / 2]

  useEffect(() => {
    void trigger('travelers.children')
  }, [trigger, watchTravelers])

  return (
    <form
      data-testid="multi-hotel-form"
      aria-label="hotel-search-form"
      onSubmit={handleSubmit((data: MULTI_HOTEL_FORM_STATE_TYPE) => {
        handleMultiHotelSubmit(data)
        logSearchFormAnalytics(cguid, 'landing', 'hotels', isMobile)
      })}
    >
      <Flex mx={-2} flexWrap="wrap">
        {watchHotels.map((hotel, index) => {
          return (
            <React.Fragment key={hotel.id}>
              <Flex
                width={1}
                mb={2}
                justifyContent="space-between"
                alignItems="center"
              >
                <StyledText pl={2} fontSize={14} bold>
                  Hotel {index + 1}
                </StyledText>
                {index > 1 && (
                  <StyledButton
                    color="primary"
                    mx={2}
                    mt={-2}
                    variation="subtle"
                    onClick={(e: SyntheticEvent) => {
                      e.preventDefault()
                      removeDestination(watchHotels, setValue, index)
                      clearErrors(`hotels.${index}`)
                    }}
                  >
                    Remove
                  </StyledButton>
                )}
              </Flex>
              <Flex flexDirection="row" flexWrap="wrap" width={1}>
                <Box width={typeaheadWidth} mb={inputFormMargin} px={2}>
                  <ShadowEffect>
                    {({
                      disableShadowState
                    }: {
                      disableShadowState: () => void
                    }) => (
                      <Box textAlign="left">
                        <TypeAhead
                          isTwoLineDisplay
                          searchProduct="hotels"
                          label="Where to?"
                          placeholder="Where to?"
                          searchKey={`hotels.${index}.endLocation`}
                          errors={errors}
                          disableShadowState={disableShadowState}
                          defaultSelectedItem={hotel.endLocation}
                          ref={(elem: HTMLInputElement) => {
                            registerTabElement(elem)
                          }}
                          showCurrentLocation={false}
                          onItemSelect={(item: LOCATION_SEARCH_TYPE) => {
                            setHotels(
                              watchHotels,
                              {
                                endLocation: item
                              },
                              setValue,
                              index
                            )
                            if (errors?.hotels?.[index]?.endLocation) {
                              void trigger(`hotels.${index}.endLocation`)
                            }
                          }}
                        />
                      </Box>
                    )}
                  </ShadowEffect>
                </Box>
                <Box
                  width={calendarWidth}
                  mb={inputFormMargin}
                  px={2}
                  textAlign="left"
                >
                  <ShadowEffect>
                    {({
                      disableShadowState
                    }: {
                      disableShadowState: () => void
                    }) => (
                      <PopoverContainer
                        open={isHotelCalendarOpen[index]}
                        data-calendar
                        onDismiss={() => {
                          setIsHotelCalendarOpen(
                            isHotelCalendarOpen.map(() => false)
                          )
                          disableShadowState()
                        }}
                        domSelectorForVisuallyContainedElement={
                          CALENDAR_POPOVER_SELECTOR
                        }
                      >
                        <DateField
                          index={index}
                          data-testid={`hotel-date-range-${index}`}
                          m={0}
                          ref={registerTabElement}
                          aria-expanded={
                            isHotelCalendarOpen[index] ? 'true' : 'false'
                          }
                          error={
                            errors?.hotels?.[index]?.startDate?.message ||
                            errors?.hotels?.[index]?.endDate?.message
                          }
                          label="Check-in - Check-out"
                          name="hotelDates"
                          onFocus={() => {
                            setIsHotelCalendarOpen(
                              isHotelCalendarOpen.map((_, i) => index === i)
                            )
                          }}
                          value={getHotelDateRange({
                            startDate: hotel.startDate,
                            endDate: hotel.endDate
                          })}
                        />
                        <CalendarWrapperBox
                          boxShadowSize={!isMobile ? 'overlay-lg' : undefined}
                          borderRadius={!isMobile ? '2xl' : undefined}
                        >
                          <Calendar
                            use2024DesignSeti
                            isMobile={isMobile}
                            roundedCorners
                            maxDaysToSelect={maxDaysToSelect}
                            monthWidthPx={350}
                            startDate={hotel.startDate}
                            endDate={hotel.endDate}
                            onChange={(_, { startDate, endDate }) => {
                              if (startDate && endDate) {
                                setIsHotelCalendarOpen(
                                  isHotelCalendarOpen.map(() => false)
                                )
                                disableShadowState()
                              }
                              const dateObj = { startDate, endDate }
                              setHotels(watchHotels, dateObj, setValue, index)
                              if (startDate)
                                void trigger(`hotels.${index}.startDate`)
                              if (endDate)
                                void trigger(`hotels.${index}.endDate`)
                            }}
                            monthsToShow={2}
                            onDismiss={() => {
                              setIsHotelCalendarOpen(
                                isHotelCalendarOpen.map(() => false)
                              )
                              disableShadowState()
                            }}
                            useDefaultFooter
                          />
                        </CalendarWrapperBox>
                      </PopoverContainer>
                    )}
                  </ShadowEffect>
                </Box>
              </Flex>
            </React.Fragment>
          )
        })}
        <Flex width={1} alignItems="center" mb={3}>
          <StyledButton
            color="primary"
            mx={2}
            p={3}
            variation="subtle"
            disabled={watchHotels.length > 4}
            onClick={(e: SyntheticEvent) => {
              e.preventDefault()
              addDestination(watchHotels, setValue)
            }}
          >
            + Add another hotel
          </StyledButton>
          <Text color="text.light" fontSize={12}>
            Add up to 5 hotels for your trip
          </Text>
        </Flex>
        <Box width={roomSelectionWidth} mb={3} px={2}>
          <ShadowEffect enabled alternativeBlurHandling>
            {({ disableShadowState }: { disableShadowState: () => void }) => (
              <Controller
                control={control}
                name="travelers"
                defaultValue={watchTravelers}
                render={({ field }) => (
                  <TravelerSelection
                    {...field}
                    initialValues={field.value}
                    trapFocus={!isIPad}
                    isMobile={isMobile}
                    height={56}
                    errorHeight={28}
                    borderRadius="lg"
                    onChange={(updatedValues: TravelerSelectionOnChange) => {
                      field.onChange({
                        childrenAges: updatedValues.childrenAges,
                        children: updatedValues.children,
                        rooms: updatedValues.rooms,
                        adults: updatedValues.adults
                      })
                      disableShadowState()
                    }}
                    roomsEnabled
                    disclaimer={VP_DISCLAIMER}
                    errorMessage={
                      errors?.travelers?.children?.message ||
                      errors?.travelers?.rooms?.message ||
                      ''
                    }
                    maxTravelers={8}
                    ageSelectEnabled
                    lapInfantLabel={false}
                    tooltipErrorMessageId="travelerSelectionError"
                  />
                )}
              />
            )}
          </ShadowEffect>
        </Box>
        <Box
          width={searchButtonWidth}
          px={2}
          mb={searchBtnMargin}
          ml="center"
          style={{
            overflowY: 'visible',
            height: !isHomepage ? '0px' : undefined
          }}
        >
          <SearchFormButton
            buttonText="Find Your Hotel"
            data-testid="HOTELS_SUBMIT_BUTTON"
          />
        </Box>
      </Flex>
    </form>
  )
}

export default MultiHotelForm
