import analytics from '@/shared-utils/analytics'
import hasGraphErrors from '@/shared-utils/graph-utils'
import isAbortSignalTimeoutSupported from '@/shared-utils/is-abortSignal-supported'
import { GraphResponse } from '@/types'
import config from 'isomorphic-config'
import getAuthorizationToken from './get-authorization-token'
import { jsonContentTypeHeader } from '../server/constants'

type ExtendStayBookingApiResponse = {
  authorizedCustomerProfile: {
    extendStayTrip: {
      travelStartDateTime: string
      travelEndDateTime: string
      cityName: string
      hotelID: string
      numRooms: number
      title: string
      stateName: string
      offerToken: string
    }
  }
}
const query = `
  query GetAuthorizedCustomerProfile($email: String!, $useDataStoreService: Boolean) {
    authorizedCustomerProfile(email: $email, useDataStoreService: $useDataStoreService) {
      extendStayTrip {
        type
        ... on HotelSummary {
          travelStartDateTime
          travelEndDateTime
          cityName
          hotelID
          numRooms
          title
          stateName
          offerToken
        }
      }
    }
  }
`

type ExtendStayBookingGraphResponse =
  GraphResponse<ExtendStayBookingApiResponse>

function hasRedeemablePayload(
  payload: unknown
): payload is ExtendStayBookingGraphResponse {
  if (payload && payload instanceof Object) {
    if ('data' in payload && payload.data && typeof payload.data === 'object') {
      return 'authorizedCustomerProfile' in payload.data
    }
  }
  return false
}

function isValidGraphQLResponse(
  payload: unknown
): payload is ExtendStayBookingGraphResponse {
  if (payload && payload instanceof Object) {
    if ('errors' in payload && Array.isArray(payload.errors)) {
      return true
    }
    if (
      'data' in payload &&
      payload.data &&
      typeof payload.data === 'object' &&
      'authorizedCustomerProfile' in payload.data
    ) {
      return true
    }
  }
  return false
}
const { url, timeout } = config.client['pcln-graph']
async function fetchExtendStayBooking(
  cguid: string,
  appName: string,
  appVersion: string,
  email: string
) {
  const authorization = getAuthorizationToken()

  const options = {
    method: 'POST',
    signal: isAbortSignalTimeoutSupported()
      ? AbortSignal.timeout(timeout)
      : undefined,
    headers: {
      ...jsonContentTypeHeader,
      'apollographql-client-name': appName,
      'apollographql-client-version': appVersion,
      Authorization: authorization
    },
    body: JSON.stringify({
      query,
      variables: {
        email,
        useDataStoreService: true
      }
    })
  } as const

  const requestUrl = `${url}?gqlOp=authorizedCustomerProfile`

  try {
    const response = await fetch(requestUrl, options)
    const { status, url: urlValue } = response
    if (!response.ok) {
      analytics.logError({
        cguid,
        message:
          'The authorizedCustomerProfile graph query response status is > 299',
        url: urlValue,
        status
      })
    } else {
      const payload = await response.json()
      if (isValidGraphQLResponse(payload)) {
        const payloadHasGraphErrors =
          hasGraphErrors<ExtendStayBookingApiResponse>(payload)
        const payloadHasRedeemableData = hasRedeemablePayload(payload)
        if (payloadHasGraphErrors) {
          const firstErrorMessage = payload.errors?.[0]?.message
          const errorMessage = firstErrorMessage || 'Unknown error'
          const errorObjectAsString = JSON.stringify(payload.errors)
          const message =
            'The authorizedCustomerProfile graph query response has returned errors.'
          analytics.logError({
            cguid,
            message,
            errorMessage,
            url: requestUrl,
            appName,
            appVersion,
            errorObjectAsString,
            hasRedeemablePayload: payloadHasRedeemableData
          })
        }
        if (
          payload.data &&
          (!payloadHasGraphErrors || payloadHasRedeemableData)
        ) {
          return payload.data.authorizedCustomerProfile.extendStayTrip
        }
      } else {
        analytics.logError({
          cguid,
          message: `The authorizedCustomerProfile graph query response is not as per contract`,
          payload: JSON.stringify(payload)
        })
      }
    }
    return null
  } catch (error) {
    const errorMessage =
      error && error instanceof Error
        ? `${error.message} Error Name: ${error.name}`
        : 'Unknown error'
    analytics.logError({
      cguid,
      message: 'Failed to call the authorizedCustomerProfile graph query.',
      errorMessage,
      error: JSON.stringify(error)
    })
    return null
  }
}

export default fetchExtendStayBooking
