import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Box, Checkbox, Flex, Label, Text } from 'pcln-design-system'
import { useForm } from 'react-hook-form'
import { Calendar, DateString, KeyboardEvt, MouseEvt } from '@pcln/date-picker'
import { pathOr } from 'ramda'
import CONFIG from 'isomorphic-config'
import useTabGroup from '@/hooks/useTabGroup'
import DateField from '@/components/DateField'
import TimeSelect from '@/components/TimeSelect/TimeSelect'
import TypeAhead from '@/components/TypeAhead'
import SearchFormButton from '@/components/SearchFormButton'
import CalendarWrapperBox from '@/components/CalenderWrapperBox.styled'
import ShadowEffect from '@/components/ShadowEffect'
import { PopoverContainer } from '@/components/Popover'
import { formatDate, getPackagesDateRange } from '@/shared-utils/date-helper'
import BundleAndSave, {
  FlightOption,
  HotelOption
} from '@/components/BundleAndSave'
import useSeti from '@/hooks/useSeti'
import TravelerSelection from '@/components/TravelerSelection/TravelerSelection'
import { VP_DISCLAIMER } from '@/components/TravelerSelection/constants'
import {
  retrieveManifest,
  insertPrefetchLinks
} from '@/shared-utils/prefetchApp'
import { yupResolver } from '@hookform/resolvers/yup'
import useBootstrapData from '@/hooks/useBootstrapData'
import logSearchFormAnalytics from '@/shared-utils/log-search-form-analytics'
import { CALENDAR_POPOVER_SELECTOR, STAY, FLY } from '../constants'
import { CAR_FORM_STATE_TYPE } from '../types'
import { fields, initialState } from './utils'
import searchFormSchema from './validation'
import { fireBundleAndSaveEvent, fireItemClickEvent } from '../ga4'

function firePclnSetiClick() {
  if (typeof window.PCLN?.pclnEventDispatcher?.dispatch === 'function') {
    window.PCLN?.pclnEventDispatcher.dispatch('FIND_YOUR_CAR')
  }
}

function SubmitButtonContainer({ handleClick }: { handleClick?: () => void }) {
  return (
    <Box width={[1, null, 1 / 2]} pl={[0, null, 2]}>
      <SearchFormButton handleClick={handleClick} buttonText="Find Your Car" />
      <Box mt={2}>
        <Text color="text" textAlign="center" fontSize={0}>
          Book a car with free cancellation for flexibility
        </Text>
      </Box>
    </Box>
  )
}

type FormType = {
  onSubmit: (data: CAR_FORM_STATE_TYPE) => void
}

export default function Form({ onSubmit }: FormType) {
  const {
    isMobile,
    webstats: { clientGUID: cguid }
  } = useBootstrapData()
  const { registerTabElement, focusNextElement } = useTabGroup()
  const [isStartCalendarOpen, setIsStartCalendarOpen] = useState(false)
  const [isEndCalendarOpen, setIsEndCalendarOpen] = useState(false)
  const [isHotelCalendarOpen, setIsHotelCalendarOpen] = useState(false)
  const [dsrManifestFetched, setDSRManifestFetched] = useState(false)
  const eventPopUpSeti =
    useSeti('MKTG_MWEB_RC_HOMEPAGE_APP_OVERLAY', isMobile) === 'VARIANT' &&
    isMobile
  const {
    clearErrors,
    handleSubmit,
    register,
    setValue,
    trigger,
    unregister,
    watch,
    formState: { errors }
  } = useForm<CAR_FORM_STATE_TYPE>({
    defaultValues: initialState,
    mode: 'onSubmit',
    resolver: yupResolver(searchFormSchema),
    reValidateMode: 'onChange'
  })

  useEffect(() => {
    fields.forEach(name => {
      register(name)
    })
    return () => {
      fields.forEach(name => {
        unregister(name)
      })
    }
  }, [register, unregister])

  const {
    adultCount: watchAdultCount,
    childrenAges: watchChildrenAges,
    childrenCount: watchChildrenCount,
    endDate: watchEndDate,
    endTime: watchEndTime,
    endLocation: watchEndLocation,
    hotelEndDate: watchHotelEndDate,
    hotelStartDate: watchHotelStartDate,
    oneWay: watchOneWay,
    roomCount: watchRoomCount,
    startDate: watchStartDate,
    startTime: watchStartTime,
    startLocation: watchStartLocation,
    tripType: watchTripType
  } = watch()

  useEffect(() => {
    void trigger('childrenAges')
  }, [
    trigger,
    watchStartLocation,
    watchEndLocation,
    watchChildrenAges,
    watchAdultCount,
    watchChildrenCount
  ])

  useEffect(() => {
    clearErrors('endDate')
  }, [clearErrors, watchEndDate])

  useEffect(() => {
    clearErrors('endLocation')
  }, [clearErrors, watchEndLocation])

  useEffect(() => {
    clearErrors('hotelStartDate')
  }, [clearErrors, watchHotelStartDate])

  useEffect(() => {
    clearErrors('roomCount')
  }, [clearErrors, watchRoomCount])

  useEffect(() => {
    clearErrors('startDate')
  }, [clearErrors, watchStartDate])

  useEffect(() => {
    clearErrors('startLocation')
  }, [clearErrors, watchStartLocation])

  useEffect(() => {
    void trigger('roomCount')
  }, [
    trigger,
    watchChildrenCount,
    watchRoomCount,
    watchAdultCount,
    watchTripType
  ])

  const hasHotel = watchTripType.includes(STAY)
  const pickupCalendarInput = useRef<HTMLButtonElement | null>(null)
  const dropoffCalendarInput = useRef<HTMLButtonElement | null>(null)
  const hasFlight = watchTripType.includes(FLY)

  const onChangePickupDate = (
    e: MouseEvt | KeyboardEvt,
    startDate: DateString | undefined,
    disableShadowState: () => void
  ) => {
    e.stopPropagation()
    if (startDate) {
      setValue('startDate', startDate, {
        shouldDirty: true
      })
      setIsStartCalendarOpen(false)
      if (startDate > watchEndDate) {
        // clear end date and open dropoff calendar
        setValue('endDate', '')
        if (!isMobile) dropoffCalendarInput.current?.focus()
      }
      disableShadowState()
    }
  }

  const onChangeDropooffDate = (
    e: MouseEvt | KeyboardEvt,
    endDate: DateString | undefined,
    disableShadowState: () => void
  ) => {
    e.stopPropagation()
    if (endDate) {
      setValue('endDate', endDate, {
        shouldDirty: true
      })
      setIsEndCalendarOpen(false)
      if (!watchStartDate || endDate < watchStartDate) {
        // clear start date and open pickup calendar
        setValue('startDate', '')
        if (!isMobile) pickupCalendarInput.current?.focus()
      }
      disableShadowState()
    }
  }

  const handleFocus = async (formTarget: React.FocusEvent) => {
    const isNotAddHotel = formTarget.target.id !== 'add-a-hotel-checkbox'
    const isNotUndefined = formTarget.target.id !== ''
    const fetchDsrManifest =
      !dsrManifestFetched && isNotAddHotel && isNotUndefined && !hasHotel
    if (fetchDsrManifest) {
      const dsrManifest = CONFIG.client.prefetch.dsr
      const links = await retrieveManifest(dsrManifest)
      insertPrefetchLinks(links)
      setDSRManifestFetched(true)
    }
  }

  const itemSelected = useCallback(() => {
    fireItemClickEvent()

    if (isMobile && document.activeElement) {
      ;(document.activeElement as HTMLElement).blur()
    }
  }, [isMobile])

  const isNotHotelOrFlight = !hasHotel && !hasFlight
  const inputText = hasFlight ? 'Where from' : 'City, Airport or Address'

  return (
    <form
      aria-label="rental-car-search-form"
      onFocus={handleFocus}
      onSubmit={handleSubmit((data: CAR_FORM_STATE_TYPE) => {
        onSubmit(data)
        logSearchFormAnalytics(cguid, 'homepage', 'cars', isMobile)
      })}
    >
      <Flex flexWrap="wrap" mx={-2}>
        {isNotHotelOrFlight && (
          <Box
            width={watchOneWay || hasFlight ? [1, null, 1 / 2] : 1}
            px={2}
            mb={[0, null, 2]}
          >
            <ShadowEffect>
              {({ disableShadowState }: { disableShadowState: () => void }) => (
                <TypeAhead
                  isTwoLineDisplay
                  searchProduct="cars"
                  errors={errors}
                  defaultSelectedItem={watchStartLocation}
                  disableShadowState={disableShadowState}
                  focusNextElement={focusNextElement}
                  label="City, Airport or Address"
                  placeholder="City, Airport or Address"
                  ref={registerTabElement}
                  searchKey="startLocation"
                  setValue={setValue}
                  onItemSelect={itemSelected}
                />
              )}
            </ShadowEffect>
          </Box>
        )}
        {(hasHotel || watchOneWay || hasFlight) && (
          <Box
            px={2}
            width={
              (!hasHotel && watchOneWay) || hasFlight ? [1, null, 1 / 2] : 1
            }
            mb={[hasFlight ? 0 : -2, null, 2]}
          >
            <ShadowEffect>
              {({ disableShadowState }: { disableShadowState: () => void }) => (
                <TypeAhead
                  isTwoLineDisplay
                  searchProduct={hasHotel ? 'hotels' : 'cars'}
                  defaultSelectedItem={
                    hasFlight ? watchStartLocation : watchEndLocation
                  }
                  disableShadowState={disableShadowState}
                  errors={errors}
                  focusNextElement={focusNextElement}
                  label={inputText}
                  placeholder={inputText}
                  ref={registerTabElement}
                  searchKey={hasFlight ? 'startLocation' : 'endLocation'}
                  setValue={setValue}
                  onItemSelect={itemSelected}
                />
              )}
            </ShadowEffect>
          </Box>
        )}
        {hasFlight && (
          <Box width={[1, null, 1 / 2]} px={2} mb={[0, null, 2]}>
            <ShadowEffect>
              {({ disableShadowState }: { disableShadowState: () => void }) => (
                <TypeAhead
                  isTwoLineDisplay
                  searchProduct="packagesOrigin"
                  errors={errors}
                  defaultSelectedItem={watchEndLocation}
                  disableShadowState={disableShadowState}
                  focusNextElement={focusNextElement}
                  label="Where to"
                  placeholder="Where to"
                  ref={registerTabElement}
                  searchKey="endLocation"
                  setValue={setValue}
                  onItemSelect={itemSelected}
                />
              )}
            </ShadowEffect>
          </Box>
        )}
      </Flex>
      {isNotHotelOrFlight && (
        <Box width={[1, null, 'auto']} mb={-1}>
          <Label htmlFor="oneWay" fontSize={[0, null, '10px']}>
            <Checkbox id="oneWay" {...register('oneWay')} />
            Return car to a different location
          </Label>
        </Box>
      )}
      <Flex
        alignItems="center"
        flexWrap={['wrap', null, null, null, 'nowrap']}
        mt={hasFlight ? 0 : 2}
        mx={-2}
        mb={[-2, null, 0]}
      >
        {isNotHotelOrFlight && (
          <>
            <CalendarWrapperBox
              mt={[2, null, 0]}
              px={2}
              mb={[0, null, 3]}
              width={[1 / 2, null, null, null, 1 / 4]}
            >
              <ShadowEffect>
                {({
                  disableShadowState
                }: {
                  disableShadowState: () => void
                }) => (
                  <PopoverContainer
                    data-calendar
                    domSelectorForVisuallyContainedElement={
                      CALENDAR_POPOVER_SELECTOR
                    }
                    key="startDate"
                    onDismiss={() => {
                      setIsStartCalendarOpen(false)
                      disableShadowState()
                    }}
                    open={isStartCalendarOpen}
                    position="left"
                  >
                    <DateField
                      index={1}
                      aria-expanded={isStartCalendarOpen ? 'true' : 'false'}
                      error={errors.startDate?.message}
                      label="Pick-up"
                      ref={pickupCalendarInput}
                      m={0}
                      name="startDate"
                      onFocus={() => {
                        setIsStartCalendarOpen(true)
                      }}
                      type="button"
                      value={formatDate(watchStartDate)}
                    />
                    <Box
                      boxShadowSize={!isMobile ? 'overlay-lg' : undefined}
                      borderRadius={!isMobile ? '2xl' : undefined}
                    >
                      <Calendar
                        use2024DesignSeti
                        isMobile={isMobile}
                        allowSameDay
                        endDate={watchEndDate}
                        roundedCorners
                        monthWidthPx={350}
                        onChange={(e, { startDate }) => {
                          onChangePickupDate(e, startDate, disableShadowState)
                        }}
                        onDismiss={() => {
                          setIsStartCalendarOpen(false)
                          disableShadowState()
                        }}
                        scrollDate={watchStartDate}
                        selectTwoDates={false}
                        showHoveredDates
                        startDate={watchStartDate}
                        useDefaultFooter
                      />
                    </Box>
                  </PopoverContainer>
                )}
              </ShadowEffect>
            </CalendarWrapperBox>
            <Box
              px={2}
              mb={[2, null, 3]}
              width={[1 / 2, null, null, null, 1 / 4]}
            >
              <ShadowEffect>
                {({
                  disableShadowState
                }: {
                  disableShadowState: () => void
                }) => (
                  <TimeSelect
                    label="Pick-up Time"
                    name="startTime"
                    onChange={(e: React.ChangeEvent) => {
                      const endTime = pathOr('', ['currentTarget', 'value'], e)
                      setValue('startTime', endTime, { shouldDirty: true })
                      setValue('endTime', endTime, { shouldDirty: true })
                      disableShadowState()
                    }}
                    value={watchStartTime}
                  />
                )}
              </ShadowEffect>
            </Box>
            <Box px={2} mb={3} width={[1 / 2, null, null, null, 1 / 4]}>
              <ShadowEffect>
                {({
                  disableShadowState
                }: {
                  disableShadowState: () => void
                }) => (
                  <PopoverContainer
                    data-calendar
                    domSelectorForVisuallyContainedElement={
                      CALENDAR_POPOVER_SELECTOR
                    }
                    key="endDate"
                    open={isEndCalendarOpen}
                    onDismiss={() => {
                      setIsEndCalendarOpen(false)
                      disableShadowState()
                    }}
                    position="left"
                  >
                    <DateField
                      index={2}
                      aria-expanded={isEndCalendarOpen ? 'true' : 'false'}
                      error={errors.endDate?.message}
                      ref={dropoffCalendarInput}
                      label="Drop-off"
                      m={0}
                      name="endDate"
                      onFocus={() => {
                        setIsEndCalendarOpen(true)
                      }}
                      type="button"
                      value={formatDate(watchEndDate)}
                    />
                    <CalendarWrapperBox
                      boxShadowSize={!isMobile ? 'overlay-lg' : undefined}
                      borderRadius={!isMobile ? '2xl' : undefined}
                    >
                      <Calendar
                        use2024DesignSeti
                        isMobile={isMobile}
                        allowSameDay
                        endDate={watchEndDate}
                        roundedCorners
                        monthWidthPx={350}
                        onChange={(e, { startDate }) => {
                          onChangeDropooffDate(e, startDate, disableShadowState)
                        }}
                        onDismiss={() => {
                          setIsEndCalendarOpen(false)
                          disableShadowState()
                        }}
                        position="left"
                        scrollDate={watchEndDate}
                        selectTwoDates={false}
                        showHoveredDates
                        startDate={watchStartDate}
                        useDefaultFooter
                      />
                    </CalendarWrapperBox>
                  </PopoverContainer>
                )}
              </ShadowEffect>
            </Box>
            <Box
              px={2}
              mb={[4, null, 3]}
              width={[1 / 2, null, null, null, 1 / 4]}
            >
              <ShadowEffect>
                {({
                  disableShadowState
                }: {
                  disableShadowState: () => void
                }) => (
                  <TimeSelect
                    label="Drop-off Time"
                    name="endTime"
                    onChange={(e: React.ChangeEvent) => {
                      const endTime = pathOr('', ['currentTarget', 'value'], e)
                      setValue('endTime', endTime, { shouldDirty: true })
                      disableShadowState()
                    }}
                    value={watchEndTime}
                  />
                )}
              </ShadowEffect>
            </Box>
          </>
        )}
        {(hasHotel || hasFlight) && (
          <>
            <Flex flexWrap="wrap" mb={[0, null, 3]} width={[1, null, 1 / 2]}>
              <CalendarWrapperBox px={2} width={1}>
                <ShadowEffect enabled={!isMobile}>
                  {({
                    disableShadowState
                  }: {
                    disableShadowState: () => void
                  }) => (
                    <PopoverContainer
                      data-calendar
                      domSelectorForVisuallyContainedElement={
                        CALENDAR_POPOVER_SELECTOR
                      }
                      open={isHotelCalendarOpen}
                      onDismiss={() => {
                        setIsHotelCalendarOpen(false)
                        disableShadowState()
                      }}
                    >
                      <DateField
                        aria-expanded={isHotelCalendarOpen ? 'true' : 'false'}
                        error={
                          errors.hotelStartDate?.message ||
                          errors.hotelEndDate?.message ||
                          ''
                        }
                        index={3}
                        label="Departing - Returning"
                        name="hotelDates"
                        onFocus={() => {
                          setIsHotelCalendarOpen(true)
                        }}
                        value={getPackagesDateRange({
                          startDate: watchHotelStartDate,
                          endDate: watchHotelEndDate
                        })}
                      />
                      <Box
                        boxShadowSize={!isMobile ? 'overlay-lg' : undefined}
                        borderRadius={!isMobile ? '2xl' : undefined}
                      >
                        <Calendar
                          use2024DesignSeti
                          isMobile={isMobile}
                          endDate={watchHotelEndDate}
                          roundedCorners
                          monthWidthPx={350}
                          onChange={(_, { startDate, endDate }) => {
                            if (startDate && endDate) {
                              setIsHotelCalendarOpen(false)
                              disableShadowState()
                            }
                            setValue('hotelStartDate', startDate || '', {
                              shouldDirty: true
                            })
                            setValue('hotelEndDate', endDate || '', {
                              shouldDirty: true
                            })
                          }}
                          onDismiss={() => {
                            setIsHotelCalendarOpen(false)
                            disableShadowState()
                          }}
                          startDate={watchHotelStartDate}
                          useDefaultFooter
                        />
                      </Box>
                    </PopoverContainer>
                  )}
                </ShadowEffect>
              </CalendarWrapperBox>
            </Flex>
            <Flex
              flexWrap="wrap"
              mb={[4, null, 3]}
              px={2}
              width={[1, null, 1 / 2]}
            >
              <ShadowEffect alternativeBlurHandling>
                {({
                  disableShadowState
                }: {
                  disableShadowState: () => void
                }) => (
                  <TravelerSelection
                    adultCount={watchAdultCount}
                    childrenAges={watchChildrenAges}
                    childrenCount={watchChildrenCount}
                    disableShadowState={disableShadowState}
                    disclaimer={VP_DISCLAIMER}
                    error={
                      errors?.childrenAges?.message ||
                      errors?.roomCount?.message ||
                      ''
                    }
                    infantCount={0}
                    onDoneButtonClick={disableShadowState}
                    roomCount={watchRoomCount}
                    setValue={setValue}
                    showChildAgeSelectors
                    childAgeSelectors={{
                      optionsText: hasFlight
                        ? {
                            infant: '0-1 infant in lap',
                            oneYear: '0-1 infant in seat'
                          }
                        : {
                            infant: 'Infant',
                            oneYear: '1 Year Old'
                          }
                    }}
                    showInfantMenuItem={false}
                    showRoomMenuItem={hasHotel}
                    showSubLabel={false}
                  />
                )}
              </ShadowEffect>
            </Flex>
          </>
        )}
      </Flex>
      <Flex flexDirection={['column', null, 'row']}>
        <Box
          width={[1, null, 1 / 2]}
          height="56px"
          pr={0}
          mb={[3, null, 0]}
          mt={[-1, null, 0]}
        >
          <BundleAndSave
            color="highlight.shade"
            label="Bundle + Save"
            productOptions={[HotelOption, FlightOption]}
            tripType={watchTripType}
            onSelectionChange={(updatedTripType, checked) => {
              setValue('tripType', updatedTripType)
              if (checked) {
                fireBundleAndSaveEvent(updatedTripType)
              }
            }}
          />
        </Box>
        <SubmitButtonContainer
          handleClick={eventPopUpSeti ? firePclnSetiClick : undefined}
        />
      </Flex>
    </form>
  )
}
