import React, { useState } from 'react'
import { Box } from 'pcln-design-system'
import StyledHeading from '@/components/StyledHeading'
import MobileTabStrip from '@/components/MobileTabStrip/MobileTabStrip'
import { ProductType } from '@/types'
import SearchFormProvider from '@/context/SearchFormContext'
import useHeadlineValues from './customHooks/useHeadlineValues'
import getProductCategory from './utils/getProductCategory'
import useFormatHotelHeadline from './customHooks/useFormatHotelHeadline'
import HeroMobile from '../Hotels/Hero/HeroMobile'
import { PRODUCT_TABS } from './constants'

export default function SearchFormMobile({
  currentTab,
  onFormChange
}: {
  currentTab: ProductType
  onFormChange: (productType: ProductType) => void
}) {
  const mobileValues = useHeadlineValues(currentTab)
  const { hotelHeadline, setLocation } = useFormatHotelHeadline(mobileValues)
  const updatedMobileValues = {
    ...mobileValues,
    hotels: {
      ...mobileValues.hotels,
      mobileHeadline: hotelHeadline
    }
  }

  const [formState, setFormState] = useState(
    getProductCategory(currentTab, updatedMobileValues, setLocation)
  )

  const handleOnTabClick = (productType: ProductType) => {
    if (productType === currentTab) {
      return
    }
    const selectedProductCategory = getProductCategory(
      productType,
      updatedMobileValues,
      setLocation
    )
    setFormState(selectedProductCategory)
    onFormChange(productType)
  }

  return (
    <Box mt={2} mb={3} boxShadowSize="xl" borderRadius="2xl">
      <MobileTabStrip
        initialActiveTab={currentTab}
        onTabClick={handleOnTabClick}
        productTabs={PRODUCT_TABS}
      />
      {currentTab === 'hotels' && <HeroMobile />}
      <SearchFormProvider>
        <Box px={3}>
          {formState.mobileHeadline && (
            <StyledHeading
              tag="h1"
              color="text.heading"
              textStyle="heading3"
              text={formState.mobileHeadline}
            />
          )}
          <Box mt={formState.mobileHeadline ? 3 : 0}>{formState.component}</Box>
        </Box>
        <Box mt={3}>{formState.bumper}</Box>
      </SearchFormProvider>
    </Box>
  )
}
