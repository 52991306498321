export type GA4ProductType = 'air' | 'hotel' | 'car' | 'bundle' | 'cruise'
export type GA4PageNameType =
  | 'homepage'
  | 'landing'
  | 'sign_in'
  | 'account_creation'

export const GA4ProductMap = {
  1: 'air',
  5: 'hotel',
  8: 'car'
} as const

export type GA4PackageFormLink = {
  event: 'select_promotion'
  attributes: {
    type: 'package'
    page_name: GA4PageNameType
    product_name: GA4ProductType
    link_name: 'advanced_search' | 'flexible_trip_link'
  }
}

export type GA4PackageCarousel = {
  event: 'select_promotion' | 'view_promotion'
  attributes: {
    type: 'package'
    page_name: GA4PageNameType
    product_name: GA4ProductType
    promotion_id: 'deal_tile'
    promotion_name: 'hotel_air'
    location_id: 'carousel'
    ecommerce: Readonly<{
      items?: ReadonlyArray<{
        item_id: string
        item_name: string
        price: number
        promotion_id: string
        promotion_name: string
      }>
      transaction_id?: string
    }>
  }
}

export type GA4SignIn = {
  event: 'login'
  attributes: {
    method: string
  }
}

export type GA4PennyTypeahead = {
  event: 'internal_link'
  attributes: {
    link_name: 'penny'
    link_item: 'type_ahead'
    page_name: GA4PageNameType
    product_name: 'hotel'
  }
}

export type GA4PennyIconDisplay = {
  event: 'display'
  attributes: {
    type: 'penny'
    page_name: GA4PageNameType
    product_name: GA4ProductType | null
  }
}

export type GA4PennyTooltipDisplay = {
  event: 'display'
  attributes: {
    type: 'penny'
    item_name: 'tooltips'
    page_name: GA4PageNameType
    product_name: GA4ProductType | null
  }
}

export type GA4PennyTooltipClick = {
  event: 'internal_link'
  attributes: {
    type: 'penny'
    link_name: 'tooltips'
    link_text: 'chat_with_penny'
    page_name: GA4PageNameType
    product_name: GA4ProductType | null
  }
}

export type GA4PennyModalOpened = {
  event: 'initiate_chat'
  attributes: {
    type: 'penny'
    page_name: GA4PageNameType
    product_name: GA4ProductType | null
  }
}

export type GA4SearchForm = {
  event: 'search'
  attributes: {
    date_change: 'y' | 'n'
    trip_start_city_change: 'y' | 'n'
    trip_end_city_change: 'y' | 'n'
    quantity_change: 'y' | 'n'
    page_name: GA4PageNameType
    product_name: GA4ProductType
    same_dropoff_location_change?: 'y' | 'n'
    cabin_class_change?: 'y' | 'n'
  }
}
export type GA4MarketingPromotionEvent = {
  event: 'select_promotion' | 'view_promotion'
  attributes: {
    type: string
    page_name: GA4PageNameType
    product_name: GA4ProductType
    promotion_name?: string
    creative_slot?: string
    creative_name?: string
  }
}
export type GA4BundlePromotionEvent = {
  event: 'select_promotion' | 'view_promotion'
  attributes: {
    type: 'add_on'
    product_name: GA4ProductType
    bundle_flag: 'y'
    bundle_type: string
    promotion_name?: string
    page_name?: GA4PageNameType
  }
}

export type GA4SelectContent = {
  event: 'select_content'
  attributes: {
    type: 'search_form'
    product_name: GA4ProductType
    item_name?: string
    page_name?: GA4PageNameType
  }
}

export type GA4InternalEvent = {
  event: 'internal_element' | 'internal_link'
  attributes: {
    page_name: GA4PageNameType
    link_name: string
    product_name?: GA4ProductType
    type?: 'search_form' | 'penny' | 'trip_grouping'
    link_item?: string
  }
}

export type GA4PageViewEvent = {
  event: 'page_view'
  attributes: {
    page_name: GA4PageNameType
    page_desc?: GA4ProductType
  }
}
// Express Deals & Cruise Form
export type GA4AbnormalSearchEvent = {
  event: 'search'
  attributes: {
    product_name: GA4ProductType
    page_name: GA4PageNameType
    item_name?: string
  }
}

type GA4TripActivityAddonSelection = {
  event: 'select_promotion'
  attributes: {
    type: 'add_on'
    creative_name: 'pick_up_where_you_left_off'
    creative_slot: 'selection'
    page_name: GA4PageNameType
    product_name: 'air'
    bundle_flag: 'y'
    bundle_type: string
  }
}

type GA4TripActivityRecentActivityPromotion = {
  event: 'select_promotion' | 'view_promotion'
  attributes: {
    type: 'recent_activity'
    creative_name: 'pick_up_where_you_left_off'
    creative_slot: 'search' | 'selection' | 'grouped_selection'
    page_name: GA4PageNameType
    product_name?: GA4ProductType
    items?: ReadonlyArray<{
      item_name: string
      item_category?: 'price_drop' | 'price_increase' | 'price_unchanged'
    }>
  }
}

type GA4TripActivityDisplay = {
  event: 'display'
  attributes: {
    type: 'link'
    item_name: 'view_all_recent_activity'
    product_name: GA4ProductType
    page_name: GA4PageNameType
  }
}

type GA4TripActivityInternalLink = {
  event: 'internal_link'
  attributes: {
    link_name: 'view_all_recent_activity'
    product_name: GA4ProductType
    page_name: GA4PageNameType
  }
}

export type GA4TripActivity =
  | GA4TripActivityRecentActivityPromotion
  | GA4TripActivityDisplay
  | GA4TripActivityInternalLink
  | GA4TripActivityAddonSelection
