import React, { useEffect } from 'react'
import { Carousel } from 'pcln-carousel'
import { RecentSearchCard } from '@pcln/trip-activity-components'
import {
  getPriceWatchProps,
  getRecentSearchDisplayProps,
  onCardClick
} from './utils'
import { RecentSearchAndPriceWatchList } from './types'
import { fireRecentSearchLoadEvent } from './ga4'

export default function RecentSearchAndPriceWatchCarousel({
  analyticsCategory,
  items
}: {
  analyticsCategory: 'PCLN_HOME' | 'HOTEL_HOME'
  items: RecentSearchAndPriceWatchList
}) {
  useEffect(() => {
    if (items.length > 0) {
      fireRecentSearchLoadEvent(items, 'PCLN_HOME')
    }
  }, [items])
  return (
    <Carousel
      arrowsPositions="top"
      arrowsPosition="side"
      currentSlide={0}
      displayArrowsMobile={false}
      slideSpacing={1}
      onSlideChange={() => {
        // do  nothing
      }}
      visibleSlides={[2.5, 2.5, 3.5]}
      mobileVisibleSlides={[1.2, 2.5]}
    >
      {items.map((item, index) => {
        if ('isPriceWatch' in item) {
          const { heading, icon, actionText, relativeUrl, priceWatch } =
            getPriceWatchProps(item)
          return (
            <RecentSearchCard
              isPriceWatch
              priceWatch={priceWatch}
              key={heading || index}
              headingWidth={['224px', null, null, '250px']}
              icon={icon}
              heading={heading}
              actionText={actionText}
              overline="Price Watch"
              onClick={() => {
                onCardClick(
                  item,
                  relativeUrl,
                  analyticsCategory,
                  item.productType
                )
              }}
              handleSubmitCTA={() => {
                onCardClick(
                  item,
                  relativeUrl,
                  analyticsCategory,
                  item.productType
                )
              }}
              className="full-width"
              isIconRounded
            />
          )
        }

        const { overline, heading, icon, actionText, relativeUrl } =
          getRecentSearchDisplayProps(item)
        return (
          <RecentSearchCard
            key={overline || index}
            headingWidth={['224px', null, null, '250px']}
            icon={icon}
            heading={heading}
            actionText={actionText}
            overline={overline}
            onClick={() => {
              onCardClick(
                item,
                relativeUrl,
                analyticsCategory,
                item.productType
              )
            }}
            handleSubmitCTA={() => {
              onCardClick(
                item,
                relativeUrl,
                analyticsCategory,
                item.productType
              )
            }}
            className="full-width"
            isIconRounded
          />
        )
      })}
    </Carousel>
  )
}
