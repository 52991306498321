import React, { useCallback, useEffect, type SyntheticEvent } from 'react'
import { useMediaQuery } from 'react-responsive'
import {
  Box,
  Heading,
  Flex,
  theme,
  Relative,
  Button,
  Text,
  Image
} from 'pcln-design-system'
import styled from 'styled-components'
import { differenceInCalendarDays } from 'date-fns/differenceInCalendarDays'
import { format } from 'date-fns/format'
import {
  UpcomingHotel,
  UpcomingFlight,
  UpcomingRentalCar,
  CrossSellCard
} from '@pcln/trip-activity-components'
import useDetectBrowser from '@/hooks/useDetectBrowser'

import {
  fireViewPromotionClick,
  fireViewPromotionXsell,
  fireViewTripClick,
  fireGetHelpClick
} from './ga4'
import type { BookedItemApiResponse } from '../TripGrouping/types'
import buildContent from './utils'

const StyledText = styled(Text)`
  white-space: nowrap;
`

function ViewMyTrips() {
  return (
    <Button
      variation="subtle"
      size="small"
      onClick={() => {
        fireViewTripClick()
        window.open('/next-profile/trips')
      }}
      mb={[2, null, null, 0]}
    >
      View My Trips
    </Button>
  )
}

function getOriginAndDestination(booking: BookedItemApiResponse) {
  switch (booking.productType) {
    case 'STAY':
      return {
        origin: '',
        destination: `${booking.cityName}, ${booking.stateCode}`,
        destinationCityName: booking.cityName
      }
    case 'FLY':
      return {
        origin: booking.originAirportInfo.code,
        destination: booking.destinationAirportInfo.code,
        destinationCityName: booking.destinationAirportInfo.city
      }
    case 'DRIVE':
      return {
        origin: '',
        destination: booking.pickupLocationCityName,
        destinationCityName: booking.pickupLocationCityName
      }
    // no default
  }
}

function TripActivityButton({
  onClick,
  booking
}: {
  onClick: () => void
  booking: BookedItemApiResponse
}) {
  const itineraryUrlWithPennyOpen = `/travel-itinerary/?openPenny=y&offerToken=${booking.offerToken}`
  return (
    <Flex ml={[0, null, 2]} width={[1, null, 'auto']} flexDirection="column">
      <Button
        size="medium"
        color="primary"
        variation="subtle"
        mb={2}
        onClick={(e: SyntheticEvent) => {
          e.stopPropagation()
          fireGetHelpClick(booking.productId)
          if (typeof window !== 'undefined') {
            window.open(itineraryUrlWithPennyOpen)
          }
        }}
      >
        <Image
          width={24}
          ml={1}
          height={24}
          src="https://s1.pclncdn.com/design-assets/home/recent-activity/penny-sparkle-icon-gradient.svg"
        />
        <StyledText pl={2} pr={3}>
          Get Help
        </StyledText>
      </Button>
      <Button
        size="medium"
        onClick={(e: SyntheticEvent) => {
          e.stopPropagation()
          onClick()
        }}
      >
        <StyledText>View Trip</StyledText>
      </Button>
    </Flex>
  )
}

function UpcomingTripCard({ booking }: { booking: BookedItemApiResponse }) {
  const itineraryUrl = `/travel-itinerary/?offerToken=${booking.offerToken}`

  const handleUpcomingTripOnClick = (bookingProductId: 1 | 5 | 8) => {
    if (typeof window !== 'undefined') {
      window.open(itineraryUrl)
    }
    fireViewPromotionClick(booking.productId, bookingProductId, booking.offerId)
  }

  const { origin, destination } = getOriginAndDestination(booking)

  const { travelEndDateTime, travelStartDateTime } = booking

  switch (booking.productId) {
    case 1: {
      const isOneWay = travelStartDateTime === travelEndDateTime
      return (
        <UpcomingFlight
          origin={origin}
          destination={destination}
          travelEndDate={travelEndDateTime}
          travelStartDate={travelStartDateTime}
          isOneWay={isOneWay}
          onClick={() => handleUpcomingTripOnClick(booking.productId)}
          buttonNode={
            <TripActivityButton
              booking={booking}
              onClick={() => handleUpcomingTripOnClick(booking.productId)}
            />
          }
        />
      )
    }
    case 5: {
      const { hotelName } = booking
      return (
        <UpcomingHotel
          hotelName={hotelName}
          destination={destination}
          travelEndDate={travelEndDateTime}
          travelStartDate={travelStartDateTime}
          truncateHeading
          onClick={() => handleUpcomingTripOnClick(booking.productId)}
          buttonNode={
            <TripActivityButton
              booking={booking}
              onClick={() => handleUpcomingTripOnClick(booking.productId)}
            />
          }
        />
      )
    }
    case 8:
      return (
        <UpcomingRentalCar
          rentalCarName="Your Upcoming Rental Car"
          destination={destination}
          travelEndDate={travelEndDateTime}
          travelStartDate={travelStartDateTime}
          onClick={() => handleUpcomingTripOnClick(booking.productId)}
          truncateHeading
          buttonNode={
            <TripActivityButton
              booking={booking}
              onClick={() => handleUpcomingTripOnClick(booking.productId)}
            />
          }
        />
      )
    // no default
  }
}

function getProductTypeById(productId: 1 | 5 | 8) {
  switch (productId) {
    case 1:
      return 'FLY'
    case 5:
      return 'STAY'
    case 8:
      return 'DRIVE'
    // no default
  }
}

export default function CrossSell({
  booking
}: {
  booking: BookedItemApiResponse
}) {
  const { xSellUrl, xSellTargetId } = buildContent(booking)
  useEffect(() => {
    fireViewPromotionXsell(booking.productId, booking.offerId)
  }, [booking])

  const isDesktop = useMediaQuery({
    query: `(min-width: ${theme.breakpoints.lg as string})`
  })

  const crossSellProduct = getProductTypeById(xSellTargetId)
  const primaryProduct = getProductTypeById(booking.productId)

  const crossSellOnClick = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation()
      fireViewPromotionClick(booking.productId, xSellTargetId, booking.offerId)
      window.open(xSellUrl)
    },
    [booking, xSellTargetId, xSellUrl]
  )

  const isBrowser = useDetectBrowser()

  const { travelStartDateTime } = booking
  const diffInDays = differenceInCalendarDays(
    format(new Date(travelStartDateTime), 'yyyy-MM-dd'),
    format(new Date(), 'yyyy-MM-dd')
  )
  const days = diffInDays < 1 ? 1 : diffInDays
  const { destinationCityName, destination } = getOriginAndDestination(booking)
  return (
    isBrowser && (
      <Relative mt={2}>
        <Flex justifyContent="space-between" px={1}>
          <Heading
            textStyle={['heading4', null, null, 'heading3']}
            color="text.heading"
            m={0}
            mb={3}
            as="h1"
          >
            {`Your trip to ${
              destinationCityName || destination
            } is in ${days} day${days > 1 ? 's' : ''}`}
          </Heading>
          {isDesktop && (
            <Box>
              <ViewMyTrips />
            </Box>
          )}
        </Flex>
        <Flex flexDirection={['column', null, null, null, 'row']}>
          <Box
            width={[1, null, null, null, 1 / 2]}
            px={1}
            mb={[2, null, null, null, 0]}
          >
            <UpcomingTripCard booking={booking} />
          </Box>
          <Box
            width={[1, null, null, null, 1 / 2]}
            px={1}
            mb={[2, null, null, null, 0]}
          >
            {crossSellProduct && primaryProduct && (
              <CrossSellCard
                crossSellProduct={crossSellProduct}
                primaryProduct={primaryProduct}
                onClick={crossSellOnClick}
              />
            )}
          </Box>
          {!isDesktop && (
            <Flex flexDirection="column" px={2} mt={2} mb={4}>
              <ViewMyTrips />
            </Flex>
          )}
        </Flex>
      </Relative>
    )
  )
}
