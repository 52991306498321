import queryString from 'query-string'
import packagesSearch from '@/shared-utils/packages-search'
import { formatDate } from '@/shared-utils/date-helper'
import analytics from '@/shared-utils/analytics'
import {
  HOTEL_SEARCH_DATA,
  persistSearchData
} from '@/shared-utils/local-forage-helper'
import { ANALYTICS_CATEGORY_HOME } from '@/constants/analytics'
import type { ChildrenAges } from '@pcln/traveler-selection'
import { type GA4PageNameType } from '@/ga4types'
import fireHotelSubmitEvent from '../ga4'
import { FLIGHT, CAR } from '../../Packages/SearchForm/TripTypes'
import { HOTEL_FORM_STATE_TYPE, HOTEL_FORM_SUBMIT_TYPE } from '../types'
import { STAY, PromoDeal } from '../constants'

export const initialState: HOTEL_FORM_STATE_TYPE = {
  endLocation: null,
  startLocation: null,
  travelers: {
    adults: 2,
    children: 0,
    lapInfants: 0,
    childrenAges: {},
    rooms: 1
  },
  startDate: '',
  endDate: '',
  tripType: STAY,
  mapView: false
}

export const fields = [
  'startLocation',
  'endLocation',
  'startDate',
  'endDate',
  'travelers',
  'tripType'
] as const

const getHotelMultiOccParam = ({
  adultCount,
  childrenAges
}: {
  adultCount: number
  childrenAges: ChildrenAges
}) => {
  let childrenCount = 0
  let childrenAgesParam = ''

  if (Array.isArray(childrenAges)) {
    childrenCount = childrenAges.length
    childrenAgesParam = childrenAges.join(',')
  } else if (typeof childrenAges === 'object') {
    const ages = Object.values(childrenAges)
    childrenCount = ages.length
    childrenAgesParam = ages.join(',')
  }

  return `/adults/${adultCount}${
    childrenCount > 0 ? `/children/${childrenAgesParam}` : ''
  }`
}

const baseURL = 'https://findgroupdeals.hotelplanner.com/Priceline.cfm?City='

export const groupDealsURL = ({
  location,
  startDate,
  endDate
}: {
  location: string
  startDate: string
  endDate: string
}) => {
  return `${baseURL}${encodeURIComponent(
    location
  )}&CheckIn=${encodeURIComponent(
    formatDate(startDate)
  )}&CheckOut=${encodeURIComponent(formatDate(endDate))}`
}

const dealTypeUrlMap = {
  [PromoDeal.EXPRESS_DEAL]: '/sopq',
  [PromoDeal.COLLECTIONS]: '/collections',
  [PromoDeal.NHA]: '/nha'
}

// this is private and used by the submit functions below
const formatHotelsURL = ({
  endLocation,
  startDate,
  endDate,
  travelers,
  dealType,
  SEMSearch,
  SEMSEARCH,
  mapView
}: HOTEL_FORM_SUBMIT_TYPE) => {
  if (endLocation === null) {
    return
  }
  const { type: searchType } = endLocation
  const updatedSearchType = searchType === 'HOTEL' ? 'CITY' : searchType
  const location =
    (searchType === 'HOTEL' ? endLocation.cityID : endLocation.id) ||
    encodeURIComponent(endLocation.itemName)

  const dealTypeParam =
    dealType === 'EXPRESS_DEAL' ||
    dealType === 'COLLECTIONS' ||
    dealType === 'NHA'
      ? dealTypeUrlMap[dealType]
      : ''

  const multiOcc = getHotelMultiOccParam({
    adultCount: travelers.adults,
    childrenAges: travelers.childrenAges
  })

  const queries = []
  if (searchType === 'HOTEL' && !dealTypeParam) {
    queries.push(`preferredhotelids=${endLocation.id}`)
  }
  if (SEMSearch) {
    queries.push(`SEMSearch=${SEMSearch}`)
  }
  if (SEMSEARCH) {
    queries.push(`SEMSEARCH=${SEMSEARCH}`)
  }

  /**
   * WARNING: Do not try this at home!
   * This application of external referral tracking on internal links
   * is NOT recommended. This is to satisfy a very specific Hotel
   * requirement and is not a robust solution to the core problem:
   * "How do we know if this is the same session as the initial referral?"
   *
   * To read more about this,
   * visit https://priceline.atlassian.net/browse/SITE-19735
   */
  const urlParams = new URLSearchParams(window.location.search)
  const refId = urlParams.get('refid')
  const refClickId = urlParams.get('refclickid')

  if (refId?.length && refClickId?.length) {
    queries.push(`refid=${refId}`)
    queries.push(`refclickid=${refClickId}`)
  }

  const query = queries.length ? `?${queries.join('&')}` : ''

  const urlDatePart =
    startDate && endDate ? `/from/${startDate}/to/${endDate}` : ''

  const mapViewPath = mapView ? 'mapview/' : ''

  if (updatedSearchType === 'POI' || updatedSearchType === 'AIRPORT') {
    return `/relax/${mapViewPath}near/${updatedSearchType.toLowerCase()}/${location}${urlDatePart}/rooms/${
      travelers.rooms
    }${dealTypeParam}${multiOcc}${query}`
  }
  return `/relax/${mapViewPath}in/${location}${urlDatePart}/rooms/${travelers.rooms}${dealTypeParam}${multiOcc}${query}`
}

export const handleSubmitForHotels = (
  values: HOTEL_FORM_SUBMIT_TYPE,
  pageName: GA4PageNameType
) => {
  const {
    startDate,
    endDate,
    endLocation,
    tripType,
    startLocation,
    travelers
  } = values

  const multiOccFields = {
    adultCount: travelers.adults,
    childrenCount: travelers.children,
    childrenAges: travelers.childrenAges
  }
  const isPackageSearch = tripType.includes(FLIGHT) || tripType.includes(CAR)
  const hasFlight = tripType.includes(FLIGHT)

  if (isPackageSearch) {
    const basePackageSearchDetails = {
      roomCount: travelers.rooms,
      startDate,
      endDate,
      endLocationObject: endLocation,
      tripType,
      isPartialStayDateValid: false,
      ...multiOccFields
    }
    const flightSearchDetails = {
      startLocationObject: startLocation,
      ...multiOccFields
    }

    const packageSearchDetails = {
      ...basePackageSearchDetails,
      ...(hasFlight ? flightSearchDetails : {})
    }
    packagesSearch(packageSearchDetails)
  } else {
    const url = formatHotelsURL(values)
    fireHotelSubmitEvent(initialState, values, pageName)
    if (url) {
      window.open(url)
    }
  }

  void persistSearchData(HOTEL_SEARCH_DATA, values)
}

export const handleSubmitForExpressDeals = (values: HOTEL_FORM_SUBMIT_TYPE) => {
  const url = formatHotelsURL({
    ...values,
    dealType: PromoDeal.EXPRESS_DEAL
  })
  window.open(url)
}

export const handleSubmitForVIPDeals = (values: HOTEL_FORM_SUBMIT_TYPE) => {
  analytics.fireEvent({
    category: ANALYTICS_CATEGORY_HOME,
    action: 'Feature Box Modal',
    label: 'Hotel Vip Search Button'
  })
  const url = formatHotelsURL({
    ...values,
    dealType: PromoDeal.VIP
  })
  window.open(url)
}

export const handleSubmitForPricebreakers = (
  values: HOTEL_FORM_SUBMIT_TYPE
) => {
  analytics.fireEvent({
    category: ANALYTICS_CATEGORY_HOME,
    action: 'Feature Box Modal',
    label: 'Hotel Collection Search Button'
  })
  const url = formatHotelsURL({
    ...values,
    dealType: PromoDeal.COLLECTIONS
  })
  window.open(url)
}

export const handleSubmitForNHA = (values: HOTEL_FORM_SUBMIT_TYPE) => {
  const url = formatHotelsURL({
    ...values,
    dealType: PromoDeal.NHA
  })
  window.open(url)
}

export const formatShopSearchLink = ({
  tripType,
  startLocation,
  endLocation,
  startDate,
  endDate,
  travelers
}: HOTEL_FORM_STATE_TYPE) => {
  const stringifiedQuery = queryString.stringify({
    'flight-code': 'RT',
    'trip-type': tripType,
    'origin-item': startLocation?.itemName,
    'origin-id': startLocation?.id,
    'origin-type': startLocation?.type,
    'origin-city': startLocation?.cityName,
    'origin-state': startLocation?.provinceName,
    'origin-state-code': startLocation?.stateCode,
    'origin-country': startLocation?.country,
    'origin-country-code': startLocation?.countryCode,
    'destination-item': endLocation?.itemName,
    'destination-id': endLocation?.id,
    'destination-type': endLocation?.type,
    'destination-city': endLocation?.cityName,
    'destination-state': endLocation?.provinceName,
    'destination-state-code': endLocation?.stateCode,
    'destination-country': endLocation?.country,
    'destination-country-code': endLocation?.countryCode,
    start: startDate,
    end: endDate,
    rooms: travelers?.rooms,
    adults: travelers?.adults,
    children: travelers?.children,
    ages: JSON.stringify(travelers?.childrenAges),
    'hotel-start': startDate,
    'hotel-end': endDate,
    openMultiHotel: true
  })

  return `/shop/search/trips/?${stringifiedQuery}`
}
