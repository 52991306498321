import React from 'react'
import styled from 'styled-components'
import { Flex, Label, Radio } from 'pcln-design-system'
import { fireMultiHotelRadioClick } from '../../ga4'

const StyledLabel = styled(Label)`
  cursor: pointer;
`

type TFormTypeRadios = {
  formType: string
  onChange: (a: string) => void
}

export default function FormTypeRadios({
  formType,
  onChange
}: TFormTypeRadios) {
  return (
    <Flex mb={2}>
      <Flex mr={3} alignItems="center">
        <StyledLabel fontSize={[10, 12]} fontWeight={500} nowrap>
          <Radio
            checked={formType === 'single'}
            onChange={() => {
              onChange('single')
            }}
          />
          Single Hotel
        </StyledLabel>
      </Flex>

      <Flex mr={3} alignItems="center">
        <StyledLabel fontSize={[10, 12]} fontWeight={500} nowrap>
          <Radio
            checked={formType === 'multi'}
            onChange={() => {
              onChange('multi')
              fireMultiHotelRadioClick()
            }}
          />
          Multi Hotel
        </StyledLabel>
      </Flex>
    </Flex>
  )
}
