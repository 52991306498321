import analytics from '@/shared-utils/analytics'
import { type GA4ProductType, GA4PageNameType } from '@/ga4types'

type ProductName = GA4ProductType | null

export function firePennyIconDisplayEvent(
  pageName: GA4PageNameType,
  productName: ProductName = null
) {
  analytics.fireGA4Event({
    event: 'display',
    attributes: {
      type: 'penny',
      page_name: pageName,
      product_name: pageName === 'homepage' ? null : productName
    }
  })
}

export function firePennyTooltipDisplayEvent(
  pageName: GA4PageNameType,
  productName: ProductName = null
) {
  analytics.fireGA4Event({
    event: 'display',
    attributes: {
      type: 'penny',
      item_name: 'tooltips',
      page_name: pageName,
      product_name: pageName === 'homepage' ? null : productName
    }
  })
}

export function firePennyTooltipClickEvent(
  pageName: GA4PageNameType,
  productName: ProductName = null
) {
  analytics.fireGA4Event({
    event: 'internal_link',
    attributes: {
      type: 'penny',
      link_name: 'tooltips',
      link_text: 'chat_with_penny',
      page_name: pageName,
      product_name: pageName === 'homepage' ? null : productName
    }
  })
}

export function firePennyModalOpenedEvent(
  pageName: GA4PageNameType,
  productName: ProductName = null
) {
  analytics.fireGA4Event({
    event: 'initiate_chat',
    attributes: {
      type: 'penny',
      page_name: pageName,
      product_name: pageName === 'homepage' ? null : productName
    }
  })
}
