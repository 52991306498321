import React, { useEffect } from 'react'
import { Hide, Container, Box } from 'pcln-design-system'
import { SpotlightCollection } from '@pcln/cards'
import { useContentfulWithPredicate } from '@pcln/contentful-components'
import { SilhouetteMarketingModule, MediaCardCollection } from '@pcln/marketing'
import PromoBanners from '@/components/MerchModuleWrapper/PromoBanners'
import AdBanner from '@/components/AdBanner'
import WhyPricelineBanner from '@/components/WhyPricelineBanner/WhyPricelineBanner'
import CreditCard from '@/components/CreditCard/CreditCard'
import { ANALYTICS_CATEGORY_HOME } from '@/constants/analytics'
import EmailSignUp from '@/components/EmailSignUp'
import PromoFeatureBox from '@/components/PromoFeatureBox'
import useExpressDealDialog from '@/hooks/useExpressDealDialog'
import useContentfulVariation from '@/hooks/useContentfulVariation'
import MarketingTout from '@/components/Promos/MarketingTout/MarketingTout'
import { REASONS_HOME } from '@/constants/reasons'
import mapSilhouetteContentfulData from '@/shared-utils/map-silhouette-contentful-data'
import IncredibleTile from '@/components/Promos/IncredibleTile'
import VIP from '@/components/Promos/VIP'
import PriceBreakers from '@/components/Promos/Pricebreakers'
import AppDownloadOptIn from '@/components/AppDownloadOptIn'
import useBootstrapData from '@/hooks/useBootstrapData'
import PackagesCarousel from '@/components/PackagesCarousel'
import ExpressDealDialog from '@/components/Hotels/ExpressDeal/ExpressDealDialog'
import LinksWidget from '@/components/SkyMondeSeo/LinksWidget'
import analytics from '@/shared-utils/analytics'
import {
  isCollectionOfIMediaCard,
  isCollectionOfIMerchModule,
  isISilhouetteMarketingModuleType,
  isISpotlightCollection
} from '@/shared-utils/content-model-type-guards'
import LayoutComponentWrapper from '@/components/LayoutComponentWrapper'
import { useFeature } from '@pcln/feature-components'
import MediaCardComponent from '@/components/Promos/MediaCard/MediaCardComponent'
import TripDisplayOrder from './TripDisplayOrder'

const expressDealQSKey = 'expressdeal'
const expressDealQSValue = 'y'

function AdBannerContent() {
  return (
    <AdBanner>
      <div id="div-gpt-ad-1614716188275-0" />
    </AdBanner>
  )
}

export default function HomeContent() {
  const {
    signInStatus: { signedIn },
    skymondeSEO
  } = useBootstrapData()
  const { isOpen, closeDialog } = useExpressDealDialog(
    expressDealQSKey,
    expressDealQSValue
  )
  const spotlightCollectionContentfulData = useContentfulVariation(
    'spotlightCollection',
    isISpotlightCollection
  )

  const promoCollection = useContentfulWithPredicate(
    'promoCollection',
    isCollectionOfIMerchModule
  )
  const promoItems = promoCollection?.items || ([] as const)

  const silhouetteContentfulData = useContentfulVariation(
    'silhouetteMarketingModule',
    isISilhouetteMarketingModuleType
  )
  const mediaCardCollectionData = useContentfulVariation(
    'mediaCardPromoCollection',
    isCollectionOfIMediaCard
  )
  const mappedSilhouetteData = silhouetteContentfulData
    ? mapSilhouetteContentfulData(
        silhouetteContentfulData,
        ANALYTICS_CATEGORY_HOME
      )
    : null

  const showBfcmEmailOptIn =
    useFeature('siteExperience.isBFCM2024EmailSignUpEnabled') ?? false

  useEffect(() => {
    const spotlightGA4params =
      spotlightCollectionContentfulData?.analyticsTracking?.parameters
    if (spotlightGA4params) {
      analytics.fireGA4Event(spotlightGA4params)
    }
  }, [spotlightCollectionContentfulData])

  return (
    <Container size="xl" mb={5}>
      <ExpressDealDialog
        isOpen={isOpen}
        closeDialog={closeDialog}
        gaCategory={ANALYTICS_CATEGORY_HOME}
      />
      <LayoutComponentWrapper>
        {showBfcmEmailOptIn && (
          <EmailSignUp
            bfcmEmailOptIn
            sourceId="LANDING_HOME"
            category={ANALYTICS_CATEGORY_HOME}
          />
        )}
        {spotlightCollectionContentfulData && (
          <SpotlightCollection
            spotlightData={spotlightCollectionContentfulData}
            onClickCallToAction={() => {
              const ga4Parameters =
                spotlightCollectionContentfulData?.callToAction
                  ?.analyticsTracking?.parameters
              if (ga4Parameters) {
                analytics.fireGA4Event(ga4Parameters)
              }
              window.open(spotlightCollectionContentfulData?.callToAction?.url)
            }}
          />
        )}
      </LayoutComponentWrapper>
      <Box mt={spotlightCollectionContentfulData || showBfcmEmailOptIn ? 4 : 0}>
        <TripDisplayOrder />
      </Box>
      <LayoutComponentWrapper topGap>
        <Hide md>
          <PromoFeatureBox>
            {signedIn ? <VIP /> : <PriceBreakers />}
            <IncredibleTile contentFulFieldID="incredibleTile" />
          </PromoFeatureBox>
        </Hide>
        <AppDownloadOptIn />
        <MarketingTout />
        {mappedSilhouetteData && (
          <SilhouetteMarketingModule {...mappedSilhouetteData} />
        )}
      </LayoutComponentWrapper>
      <Box my={4}>
        <PackagesCarousel
          heading="Don't miss these hotel + flight deals"
          gaCategory={ANALYTICS_CATEGORY_HOME}
        />
      </Box>
      <LayoutComponentWrapper>
        {!showBfcmEmailOptIn && (
          <EmailSignUp
            sourceId="LANDING_HOME"
            category={ANALYTICS_CATEGORY_HOME}
          />
        )}
        <MediaCardCollection
          collection={mediaCardCollectionData}
          cardNode={MediaCardComponent}
        />
        <PromoBanners merchModules={promoItems} />
        <CreditCard />
        <WhyPricelineBanner content={REASONS_HOME} />
        <AdBannerContent />
        {skymondeSEO && <LinksWidget data={skymondeSEO} />}
      </LayoutComponentWrapper>
    </Container>
  )
}
