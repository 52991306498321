import React, { useState, useEffect } from 'react'
import config from 'isomorphic-config'
import PclnUcChatBot, {
  HandleMarkerResponse,
  localCache,
  SignInMarkerComponent
} from '@pcln/uc-chat-bot'
import { v4 as uuidv4 } from 'uuid'
import useBootstrapData from '@/hooks/useBootstrapData'
import analytics from '@/shared-utils/analytics'
import useGeoPosition from '@/hooks/useGeoPosition'
import useSeti from '@/hooks/useSeti'
import { GA4ProductType, GA4PageNameType } from '@/ga4types'
import getCityListComponent from './getCityListComponent'
import { firePennyIconDisplayEvent, firePennyModalOpenedEvent } from './ga4'

const pennyMarkers = {
  JSONOBJECT_CITIES: 'JSONOBJECT_CITIES',
  PROMPT_SIGN_IN: 'PROMPT_SIGN_IN'
} as const

const MODAL_ARIA_LABEL = 'Travel Bot Chat Dialog Modal'
const MODAL_ARIA_TITLE = 'Travel Bot Chat Dialog'

const { JSONOBJECT_CITIES, PROMPT_SIGN_IN } = pennyMarkers

export const handleMarkerResponse: (
  signedIn: boolean
) => HandleMarkerResponse = signedIn => (err, marker, fullText) => {
  try {
    if (err) {
      throw err
    } else if (marker === JSONOBJECT_CITIES) {
      return {
        role: 'assistant',
        content: '',
        promptContent: 'Displayed city list to the user.',
        markerComponentProps: { prefixString: fullText }
      }
    } else if (marker === PROMPT_SIGN_IN) {
      return {
        role: 'assistant',
        content: '',
        promptContent: 'Displayed signin marker component.',
        markerComponentProps: { signedIn }
      }
    }
    return {
      role: '',
      content: ''
    }
  } catch (error) {
    const catchErrorMessage = JSON.stringify(error)

    analytics.logError({
      message: `ChatBot - handle marker response error: ${catchErrorMessage}`
    })

    return {
      role: 'assistant',
      content: 'Network error, please retry.'
    }
  }
}

function TravelChatBotModal(props: {
  isChatBotDialogOpened: boolean
  initialPrompt: string
  onModalClose: () => void
  pageName: GA4PageNameType
  productType?: GA4ProductType
}) {
  const [discoveryCitySelected, setDiscoveryCitySelected] = useState('')
  const {
    isChatBotDialogOpened,
    onModalClose,
    initialPrompt,
    pageName,
    productType
  } = props
  const [askPromptClosed, setAskPromptClose] = useState(false)
  const {
    isMobile,
    webstats,
    customer: { firstName },
    locale,
    signInStatus: { signedIn },
    moduleInfo
  } = useBootstrapData()
  const componentVersion = moduleInfo?.['@pcln/uc-chat-bot']

  const isLoadMoreListingsCard =
    useSeti('CHATBOT_HOTEL_CARDS_LOAD_MORE', false) === 'VARIANT'

  const enableRealTime =
    useSeti('CHATBOT_VOICE_REALTIME_HOME_PAGE', false) === 'VARIANT' && signedIn

  const shouldEnableFeedbackIntent =
    useSeti('CHATBOT_FEEDBACK_SURVEY', false) === 'VARIANT'

  useEffect(() => {
    firePennyIconDisplayEvent(pageName, productType)
  }, [pageName, productType])

  useEffect(() => {
    if (isChatBotDialogOpened) {
      firePennyModalOpenedEvent(pageName, productType)
    }
  }, [isChatBotDialogOpened, pageName, productType])

  const setDiscoveryCity = (city: string) => {
    setDiscoveryCitySelected(city)
  }
  const markerComponents = {
    [JSONOBJECT_CITIES]: getCityListComponent(setDiscoveryCity),
    [PROMPT_SIGN_IN]: SignInMarkerComponent
  }

  const { latitude, longitude } = useGeoPosition()

  const shouldDisplaySurvey = useSeti('PENNY_SURVEY_CHAT_HISTORY') === 'VARIANT'

  const shouldUseMapSeti = useSeti('CHATBOT_HOTEL_MAP', false)

  const shouldEnableInlineLocationMetadata = shouldUseMapSeti === 'VARIANT'

  const markerResponseHandler = handleMarkerResponse(signedIn)

  const mobileHeaderValue = isMobile ? 'Y' : 'N'

  return (
    <PclnUcChatBot
      componentVersion={componentVersion}
      chatContainerHeight="85%"
      onClose={() => {
        setAskPromptClose(true)
        onModalClose()
      }}
      dialogOverlayProps={{
        ariaDescription: MODAL_ARIA_LABEL,
        ariaTitle: MODAL_ARIA_TITLE,
        fullWidth: isMobile
      }}
      markerComponents={markerComponents}
      handleMarkerResponse={markerResponseHandler}
      discoveryCitySelected={discoveryCitySelected}
      isMobile={isMobile}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      logFirstMessageSubmitted={() => {}}
      fetchRequestOptions={{
        url: `${config.client.uc.chatbot}?useMap=${shouldUseMapSeti}`,
        headers: {
          'x-request-from': 'homePage',
          'x-is-mobile': mobileHeaderValue,
          'x-model-version': '4',
          'x-product-id': '5'
        }
      }}
      transcriptionFetchRequestOptions={{
        url: `${config.client.uc.transcriptionUrl}`,
        timeout: config.client.uc.transcriptionTimeout
      }}
      speechFetchRequestOptions={{
        url: `${config.client.uc.speechUrl}`,
        timeout: config.client.uc.speechTimeout
      }}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onChatDialogRendered={() => {}} // not needed right now, the analytics event is fired elsewhere on open
      isChatBoxOpened={isChatBotDialogOpened}
      cachedPrompts={
        initialPrompt?.length > 0 ? [] : localCache.getLocalPrompts()
      }
      cachedMessages={
        initialPrompt?.length > 0 ? [] : localCache.getLocalMessages()
      }
      setCachedPrompts={localCache.setLocalPrompts}
      setCachedMessages={localCache.setLocalMessages}
      shouldDisplayGenHotelFeature
      isRealtime={enableRealTime}
      setiValues={{
        shouldDisplayCheckoutButton: true,
        shouldDisplaySurvey,
        enableVoice: true,
        enableVoiceTooltip: true,
        enableMyTripsSignIn: true
      }}
      submitChatMessagesPayload={{
        hotelPayload: {
          shouldUseSearchHotelsFeature: true,
          isHotelSearchDateSelection: true,
          isShowMoreListingCards: isLoadMoreListingsCard
        },
        enabledActions: {
          shouldEnableFeedbackIntent,
          shouldUseDynamicQuickActionsChips: true,
          shouldEnableInlineLocationMetadata
        },
        isSessionActive: true,
        customerName: firstName,
        productId: 5,
        latitude: locale?.latitude || latitude || '',
        longitude: locale?.longitude || longitude || '',
        cityName: locale?.city,
        stateCode: locale?.region,
        countryCode: locale?.countryName,
        rguid: `HOMEPAGE-${uuidv4()}`,
        cguid: webstats?.clientGUID || '',
        dateToday: new Date().toString(),
        returnJSONResponse: true,
        pushPrompt: askPromptClosed ? undefined : initialPrompt
      }}
    />
  )
}

TravelChatBotModal.displayName = 'TravelChatBotModal'

export default TravelChatBotModal
