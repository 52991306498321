import * as yup from 'yup'
import { isEmpty } from 'ramda'
import { LOCATION_SEARCH_TYPE } from '@/types'
import { HOTEL, ONE_WAY, MULTI_DESTINATION, DRIVE } from '../constants'
import type { FlightFormStateType } from '../types'

const AIRPORT_ERROR = 'Please enter an airport'
const INFANT_CAPACITY_LIMIT = 'Only one infant per adult is allowed'
const DISALLOW_INFANT_INTL_FLIGHT =
  'Sorry, we do not offer lap infant seating on international flights'

function isIntlLocation(location?: LOCATION_SEARCH_TYPE) {
  return (
    typeof location?.countryCode === 'string' &&
    location.countryCode.toUpperCase() !== 'US'
  )
}

function isInfantFound(infantCount?: number) {
  return typeof infantCount === 'number' && infantCount > 0
}

function isIntlLocationWithInfant(
  infantCount?: number,
  location?: LOCATION_SEARCH_TYPE
) {
  return isIntlLocation(location) && isInfantFound(infantCount)
}

function handleInfantTravel(
  this: yup.TestContext,
  formState: FlightFormStateType
) {
  const { adults, lapInfants } = this.parent
  const { flights, tripType } = formState
  const { startLocation, endLocation } = flights[0]
  if (
    (tripType.includes(HOTEL) || tripType.includes(DRIVE)) &&
    (isIntlLocationWithInfant(lapInfants, startLocation) ||
      isIntlLocationWithInfant(lapInfants, endLocation))
  ) {
    return this.createError({
      message: DISALLOW_INFANT_INTL_FLIGHT,
      path: this.path
    })
  }

  if (adults < lapInfants) {
    return this.createError({
      message: INFANT_CAPACITY_LIMIT,
      path: this.path
    })
  }

  return true
}

const SCHEMA_OW = yup.object().shape({
  endLocation: yup
    .object()
    .transform((val: LOCATION_SEARCH_TYPE) => {
      return isEmpty(val) || val === null ? undefined : val
    })
    .required(AIRPORT_ERROR)
    .test(
      'identical locations',
      "Departure and arrival airport can't be the same",
      function areDistinctLocations(
        this: yup.TestContext,
        val: { id?: string }
      ): boolean {
        const ref: LOCATION_SEARCH_TYPE = this.resolve(yup.ref('startLocation'))

        if (ref?.id === val?.id) {
          return false
        }
        return true
      }
    ),
  startDate: yup.string().required(),
  startLocation: yup
    .object()
    .transform((val: LOCATION_SEARCH_TYPE) => {
      return isEmpty(val) || val === null ? undefined : val
    })
    .required(AIRPORT_ERROR)
})

const SCHEMA_RT = SCHEMA_OW.concat(
  yup.object().shape({
    endDate: yup.string().required()
  })
)

const searchFormSchema = yup.object({
  flightType: yup.string(),
  flights: yup.array().when('flightType', {
    is: (val: string) => val === ONE_WAY || val === MULTI_DESTINATION,
    then: schema => schema.of(SCHEMA_OW),
    otherwise: schema => schema.of(SCHEMA_RT)
  }),
  travelers: yup.object({
    lapInfants: yup.number().test({
      name: 'lapInfants',
      test() {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return handleInfantTravel.call(this, this.options.from[1].value)
      }
    })
  })
})

export default searchFormSchema
