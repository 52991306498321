import React from 'react'
import { Box, SrOnly } from 'pcln-design-system'
import {
  GetItemPropsOptions,
  GetMenuPropsOptions,
  GetPropsCommonOptions
} from 'downshift'
import INPUT_MIN_WIDTH from '@/constants/dropdown'
import { LOCATION_SEARCH_TYPE, TypeAheadConfigEndpoint } from '@/types'
import DropdownCard from './DropdownCard.styled'
import FetchTypeAhead from '../FetchTypeAhead'
import SearchItem from './SearchItem'
import RenderCurrentLocation from './RenderCurrentLocation'
import RenderRecentSearches from './RenderRecentSearches'
import { CurrentLocationMachineState } from '../../../machines/currentLocationMachine'
import TypeaheadPenny from '../TypeaheadPenny'

type DropdownProps = {
  isOpen: boolean
  highlightedIndex: number | null
  getItemProps: (
    options: GetItemPropsOptions<LOCATION_SEARCH_TYPE>
  ) => Record<string, unknown>
  getMenuProps: (
    options?: GetMenuPropsOptions,
    otherOptions?: GetPropsCommonOptions
  ) => Record<string, unknown>
  searchKey: string
  searchValue: string | null
  clearItems: () => void
  setHighlightedIndex: (index: number) => void
  showCurrentLocation?: boolean
  isMobile?: boolean
  current?: CurrentLocationMachineState
  handleRequestCurrentLocationClick?: () => void
  typeAheadEndpoint: TypeAheadConfigEndpoint
  recentSearches?: LOCATION_SEARCH_TYPE[]
  isTwoLineDisplay?: boolean
  recentOrRecommendationTitle: string
  nearbyAirportsFeatureEnabled?: boolean
  shouldDisplayPenny?: boolean
}

function Dropdown({
  isOpen,
  highlightedIndex,
  getItemProps,
  getMenuProps,
  searchKey,
  searchValue,
  clearItems,
  current,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleRequestCurrentLocationClick = () => {},
  showCurrentLocation = false,
  setHighlightedIndex,
  isMobile = false,
  typeAheadEndpoint,
  recentSearches = [],
  recentOrRecommendationTitle,
  isTwoLineDisplay,
  shouldDisplayPenny
}: DropdownProps) {
  return isOpen ? (
    <FetchTypeAhead
      searchValue={searchValue}
      searchKey={searchKey}
      typeAheadEndpoint={typeAheadEndpoint}
      onLoaded={() => {
        clearItems()
        setHighlightedIndex(0)
      }}
      render={({ items }: { items: LOCATION_SEARCH_TYPE[] }) => {
        const isCardVisible = isOpen && items.length > 0
        const isRecentSearchesVisible =
          isOpen && recentSearches.length && searchValue === ''
        return (
          <>
            {isCardVisible && (
              <SrOnly aria-live="polite">
                {items.length} number of results
              </SrOnly>
            )}
            <DropdownCard
              boxShadowSize={isMobile ? undefined : 'overlay-lg'}
              data-testid="typeahead-dropdown-card"
              color="background.lightest"
              borderRadius={shouldDisplayPenny ? ['none', null, 'xl'] : 'xl'}
              mt={isMobile ? 0 : 1}
              role="listbox"
              rounded={isMobile ? 'bottom' : undefined}
              width={1}
              minWidth={isMobile ? '' : INPUT_MIN_WIDTH}
              visible={
                isCardVisible || isRecentSearchesVisible || showCurrentLocation
              }
              {...getMenuProps({
                refKey: 'ref',
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                isOpen
              })}
            >
              <Box px={[3, null, 2]} py={2}>
                {showCurrentLocation && current && (
                  <RenderCurrentLocation
                    current={current}
                    handleRequestCurrentLocationClick={
                      handleRequestCurrentLocationClick
                    }
                  />
                )}
                {isRecentSearchesVisible ? (
                  <RenderRecentSearches
                    getItemProps={getItemProps}
                    recentSearches={recentSearches}
                    highlightedIndex={highlightedIndex}
                    recentOrRecommendationTitle={recentOrRecommendationTitle}
                  />
                ) : null}
                {isCardVisible
                  ? items.map((item, index) => (
                      <SearchItem
                        isTwoLineDisplay={isTwoLineDisplay}
                        key={item.itemName}
                        isItemSelected={index === highlightedIndex}
                        index={index}
                        item={item}
                        getItemProps={getItemProps}
                      />
                    ))
                  : null}
              </Box>
              {shouldDisplayPenny && (
                <TypeaheadPenny
                  isRecentSearchesVisible={!!isRecentSearchesVisible}
                />
              )}
            </DropdownCard>
          </>
        )
      }}
    />
  ) : null
}

export default Dropdown
