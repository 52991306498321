import React, { useState } from 'react'
import { GetServerSideProps } from 'next'
import { ContentfulProvider } from '@pcln/contentful-components'
import { FeatureProvider } from '@pcln/feature-components'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import Layout from '@/components/Layout'
import type { CustomContext, ContentPageProps } from '@/types'
import Home from '@/components/Home'
import BANNER_TEXT_VALUES from '@/components/AppBanner/constants'
import { HOME_PRODUCT_TYPE } from '@/constants/products'
import AppBanner from '@/components/AppBanner'
import TmsScript from '@/components/TmsScript/TmsScript'
import DEFAULT_META_TAGS from '@/constants/meta'
import Meta from '@/components/Meta'
import Cobrand from '@/components/Cobrand'
import useCanonicalUrl from '@/hooks/useCanonicalUrl'
import usePageTitleForHotels from '@/hooks/usePageTitleForHotels'
import oktaJwtVerify from '../server/utils/okta-jwt-verifier'
import profileData from '../server/utils/profile-data'
import prePopulateHotelValues from '../server/prepop/hotels'
import getSkymondeData from '../server/utils/get-skymonde-data'
import generateBootstrapData from '../utils/generate-bootstrap-data'
import fetchPCLNHeaderFooterContent from '../server/utils/pcln-header-footer'
import getCookies from '../server/utils/get-cookies'
import setiService from '../server/middleware/seti'
import mapSetiResponse from '../utils/map-seti-response'
import getContentfulData from '../server/utils/get-contentful-data'
import retrieveContentfulSecrets from '../server/utils/retrieve-contentful-secrets'

function Homepage({
  headerHTML,
  footerHTML,
  bootstrapData,
  content
}: ContentPageProps) {
  const pageTitle = usePageTitleForHotels(DEFAULT_META_TAGS.title.home)
  const canonicalUrl = useCanonicalUrl()
  const [queryClient] = useState(() => new QueryClient())
  return (
    <>
      <Meta
        description={DEFAULT_META_TAGS.description.home}
        pageTitle={pageTitle}
        canonicalUrl={canonicalUrl}
      />
      <TmsScript product="home" />
      <FeatureProvider features={bootstrapData.featureToggles}>
        <ContentfulProvider contentful={content}>
          <QueryClientProvider client={queryClient}>
            <AppBanner content={BANNER_TEXT_VALUES[HOME_PRODUCT_TYPE]} />
            <Layout headerHTML={headerHTML} footerHTML={footerHTML}>
              <Cobrand />
              <Home />
            </Layout>
          </QueryClientProvider>
        </ContentfulProvider>
      </FeatureProvider>
    </>
  )
}

export const getServerSideProps: GetServerSideProps<
  ContentPageProps
> = async ctx => {
  const { req, res } = ctx as unknown as CustomContext
  const dmc = getCookies(req.headers).dmc || ''
  const reqAuthHeader = req.headers.authorization || ''
  const bootstrapData = generateBootstrapData('home', req, res)
  const { webstats, referral, customer, appVersion, locale } = bootstrapData
  const { emailAddress: email = '' } = customer
  const { clientGUID: cguid } = webstats
  const { contentfulDeliverySecret, contentfulPreviewSecret } =
    retrieveContentfulSecrets()
  const isContentfulEnabled =
    res.locals.features?.siteExperience.isContentfulEnabled ?? true
  const isSkymondeEnabled =
    res.locals.features?.siteExperience?.isSkymondeEnabled ?? false
  const [signInStatus, { content, experimentNames }] = await Promise.all([
    oktaJwtVerify({ authHdr: req.headers.authorization ?? '', cguid }),
    getContentfulData({
      contentTypeId: 'pageHome',
      contentfulDeliverySecret,
      contentfulPreviewSecret,
      contentPreview: ctx.query['content-preview'] === 'true',
      isContentfulEnabled
    })
  ])

  const [
    setiResponseEvents,
    profileDataResponse,
    hotelPrePopData,
    pclnHeaderFooterContent,
    skymondeSEO
  ] = await Promise.all([
    setiService(req, {
      appVersion,
      allowSetiOverrides: true,
      webstats,
      referral,
      reqQueryParams: ctx.query,
      ...(experimentNames && {
        additionalExperiments: experimentNames
      })
    }),
    profileData({
      dmc,
      cguid,
      req,
      email,
      signedIn: signInStatus.signedIn,
      authorization: reqAuthHeader
    }),
    prePopulateHotelValues(ctx.query),
    fetchPCLNHeaderFooterContent(req, res),
    getSkymondeData('home', locale.localeCode, isSkymondeEnabled)
  ])
  const { loyaltyTier } = profileDataResponse
  const prePopData = hotelPrePopData ? { hotels: hotelPrePopData } : null

  return {
    props: {
      ...pclnHeaderFooterContent,
      content,
      bootstrapData: {
        ...bootstrapData,
        fullSETI: setiResponseEvents,
        seti: mapSetiResponse(setiResponseEvents),
        signInStatus,
        vipTier: loyaltyTier,
        ...(prePopData && { prePopData }),
        skymondeSEO
      }
    }
  }
}

export default Homepage
