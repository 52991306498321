import React from 'react'
import { format } from 'date-fns/format'
import { parse } from 'date-fns/parse'
import { parseISO } from 'date-fns/parseISO'
import { HotelsFill, CarsFill, FlightsFill } from '@pcln/brand'
import { isToday, isAfter } from 'date-fns'
import { FLY_CABIN_CLASS } from './constants'
import type {
  AbandonedHotelSelectionApiResponse,
  AbandonedCarSelectionApiResponse,
  AbandonedFlightSelectionApiResponse,
  AbandonedPriceBreakersHotel,
  RTLHotel,
  SopqHotel,
  RecentTripSearches,
  PriceWatchData,
  AbandonedFlightPath,
  AbandonedSelectionApiResponse,
  AbandonedSelection,
  PriceWatchDataWithFlags,
  SearchProductDataFiltered,
  RecentSearchAndPriceWatchList
} from './types'
import { TripGroupingApiResponse } from '../TripGrouping/types'
import fireRecentSearchCardClickEvent, {
  fireViewAllButtonClickEvent
} from './ga4'

const DATE_FORMAT = 'E, MMM dd'

function getDates({
  startDate,
  endDate = ''
}: {
  startDate: string
  endDate?: string | null
}) {
  const formatStartDate = startDate.split('T')[0]
  const formatEndDate = endDate?.split('T')[0]
  // ONE-WAY
  if (!formatEndDate) {
    return [
      format(parse(formatStartDate, 'yyyy-MM-dd', new Date()), 'yyyyMMdd'),
      format(parse(formatStartDate, 'yyyy-MM-dd', new Date()), 'yyyyMMdd')
    ]
  }
  return [
    format(parse(formatStartDate, 'yyyy-MM-dd', new Date()), 'yyyyMMdd'),
    format(parse(formatEndDate, 'yyyy-MM-dd', new Date()), 'yyyyMMdd')
  ]
}

function formatDates({
  startDate = '',
  endDate = '',
  dateFormat = DATE_FORMAT
}) {
  return [
    format(parse(startDate, 'yyyyMMdd', new Date()), dateFormat),
    format(parse(endDate, 'yyyyMMdd', new Date()), dateFormat)
  ]
}

export function getRecentSearchDisplayProps(item: RecentTripSearches) {
  switch (item.productType) {
    case 'FLY': {
      const {
        travelStartDate,
        travelEndDate,
        listingsUrl,
        tripType,
        originAirportInfo,
        destinationAirportInfo
      } = item

      const [startDate, endDate] = getDates({
        startDate: travelStartDate,
        endDate: travelEndDate
      })
      const [formattedStartDate, formattedEndDate] = formatDates({
        startDate,
        endDate
      })
      const {
        city: originCity,
        name: originName,
        state: originState,
        country: originCountry
      } = originAirportInfo
      const {
        city: destinationCity,
        name: destinationName,
        state: destinationState,
        country: destinationCountry
      } = destinationAirportInfo
      return {
        icon: (
          <div style={{ lineHeight: '0px' }}>
            <FlightsFill size="24px" />
          </div>
        ),
        overline: 'Flight',
        heading: `${
          tripType === 'Round Trip'
            ? `${originCity || originName}, ${originState || originCountry} - `
            : ``
        }${destinationCity || destinationName}, ${
          destinationState || destinationCountry
        }`,
        actionText: `${formattedStartDate}${
          item.tripType === 'Round Trip' ? ` - ${formattedEndDate}` : ``
        }`,
        relativeUrl: listingsUrl
      }
    }
    case 'DRIVE': {
      const {
        pickupDateTime,
        returnDateTime,
        pickupLocationName,
        returnLocationName,
        listingsUrl
      } = item
      const [startDate, endDate] = getDates({
        startDate: pickupDateTime,
        endDate: returnDateTime
      })
      const [formattedStartDate, formattedEndDate] = formatDates({
        startDate,
        endDate
      })
      return {
        icon: (
          <div style={{ lineHeight: '0px' }}>
            <CarsFill size="24px" />
          </div>
        ),
        overline: 'Cars',
        heading: `${pickupLocationName}${
          pickupLocationName !== returnLocationName
            ? ` - ${returnLocationName}`
            : ''
        }`,
        actionText: `${formattedStartDate} - ${formattedEndDate}`,
        relativeUrl: listingsUrl
      }
    }
    default: {
      const { cityName, checkInDate, checkOutDate, listingsUrl } = item
      const [startDate, endDate] = getDates({
        startDate: checkInDate,
        endDate: checkOutDate
      })
      const [formattedStartDate, formattedEndDate] = formatDates({
        startDate,
        endDate
      })
      return {
        icon: (
          <div style={{ lineHeight: '0px' }}>
            <HotelsFill size="24px" />
          </div>
        ),
        overline: 'Hotels',
        heading: cityName,
        actionText: `${formattedStartDate} - ${formattedEndDate}`,
        relativeUrl: listingsUrl
      }
    }
  }
}

function transformAbandonedCarApiResponseToProps(
  item: AbandonedCarSelectionApiResponse
) {
  const {
    carURL,
    carType,
    carExample,
    pickupLocation,
    pickupDateTime,
    returnDateTime,
    isOffAirportPickup,
    isDropOffAtDifferentLocation,
    retailTypeCarCompanyDetails,
    passengerCapacity,
    returnLocation,
    imageURL,
    genericListingsUrl
  } = item
  const parsedCheckInDate = parse(
    pickupDateTime,
    "yyyy-MM-dd'T'HH:mm:ss",
    new Date()
  )
  const formattedCheckInDate = format(
    parsedCheckInDate,
    "EEE, MMM dd, yyyy 'at' h:mmaaa"
  )
  const parsedCheckOutDate = parse(
    returnDateTime,
    "yyyy-MM-dd'T'HH:mm:ss",
    new Date()
  )
  const formattedCheckOutDate = format(
    parsedCheckOutDate,
    "EEE, MMM dd, yyyy 'at' h:mmaaa"
  )
  return {
    headline: carType,
    expressDealText: carExample,
    pickupLocation,
    isDropOffAtDifferentLocation,
    returnLocation,
    specifyOffAirport: isOffAirportPickup,
    pickUpDate: formattedCheckInDate,
    dropOffDate: formattedCheckOutDate,
    numAdults: passengerCapacity.toString(),
    linkUrl: carURL,
    genericListingsUrl,
    imageSrc: imageURL,
    rcDealType: 'RETAIL',
    productType: 'cars' as const,
    brandImg: retailTypeCarCompanyDetails?.brandImageURL
      ? `${retailTypeCarCompanyDetails.brandImageURL}&opto&auto=webp&width=176`
      : undefined
  }
}

function isSopqHotel(
  hotelData: RTLHotel | SopqHotel | AbandonedPriceBreakersHotel
): hotelData is SopqHotel {
  /* eslint-disable-next-line no-underscore-dangle */
  return hotelData.__typename === 'SopqHotel'
}

function isPriceBreaker(
  hotelData: RTLHotel | SopqHotel | AbandonedPriceBreakersHotel
): hotelData is AbandonedPriceBreakersHotel {
  /* eslint-disable-next-line no-underscore-dangle */
  return hotelData.__typename === 'AbandonedPriceBreakersHotel'
}

function parseAbandonedHotelPriceData(pricePerNightAtAbandonment: string) {
  return pricePerNightAtAbandonment.replace(/[^0-9.]/g, '')
}

function transformAbandonedHotelApiResponseToProps(
  item: AbandonedHotelSelectionApiResponse
) {
  const {
    hotelURL,
    genericListingsUrl,
    checkInDate,
    checkOutDate,
    numberOfAdults,
    numberOfRooms,
    pricePerNightAtAbandonment
  } = item

  const parsedCheckInDate = parse(checkInDate, 'yyyy-MM-dd', new Date())
  const formattedCheckInDate = format(parsedCheckInDate, 'EEE, MMM dd, yyyy')
  const parsedCheckOutDate = parse(checkOutDate, 'yyyy-MM-dd', new Date())
  const formattedCheckOutDate = format(parsedCheckOutDate, 'EEE, MMM dd, yyyy')
  const commonDetails = {
    checkInDate: formattedCheckInDate,
    checkOutDate: formattedCheckOutDate,
    numAdults: numberOfAdults,
    numRooms: numberOfRooms,
    linkUrl: hotelURL,
    genericListingsUrl,
    productType: 'hotels' as const,
    pricePerNightAtAbandonment: parseAbandonedHotelPriceData(
      pricePerNightAtAbandonment
    )
  }
  const hotelData = item.hotelInfo

  if (isSopqHotel(hotelData)) {
    const { __typename, name, neighborhood, location } = hotelData

    const neighborhoodName = neighborhood?.name || ''
    const locationName = location?.name || ''
    const hotelDescription = name || ''
    return {
      ...commonDetails,
      hotelDealType: __typename,
      headline: `${hotelDescription} in ${neighborhoodName}`,
      subheadline: locationName
    }
  }

  if (isPriceBreaker(hotelData)) {
    const { __typename, pricebreakerDescription, pricebreakerLocation } =
      hotelData
    return {
      ...commonDetails,
      hotelDealType: __typename,
      headline: pricebreakerDescription,
      subheadline: pricebreakerLocation
    }
  }

  const {
    thumbnail,
    name,
    starRating,
    reviewSummaryWithEntityInput,
    neighborhood,
    __typename
  } = hotelData

  const source = thumbnail?.source
  const rating = starRating || ''
  const totalReviews = reviewSummaryWithEntityInput?.totalReviews || 0
  const overall = reviewSummaryWithEntityInput?.scoreBreakdown?.overall || ''
  const neighborhoodName = neighborhood?.name || ''

  return {
    ...commonDetails,
    hotelDealType: __typename,
    headline: name || '',
    subheadline: neighborhoodName,
    numReviews: totalReviews,
    numStars: parseInt(rating, 10),
    overallRating: parseFloat(overall),
    imageSrc: source
  }
}

function isTripDateValid(tripDate: string, formatString: string) {
  const parseTripDate = parse(tripDate, formatString, new Date())

  return isToday(parseTripDate) || isAfter(parseTripDate, new Date())
}

function isValidAbandonedHotelSelection(
  selection: AbandonedSelectionApiResponse
): selection is AbandonedHotelSelectionApiResponse {
  return (
    'hotelURL' in selection &&
    isTripDateValid(selection.checkInDate, 'yyyy-MM-dd') &&
    !selection.isBooked
  )
}

function isValidAbandonedCarSelection(
  selection: AbandonedSelectionApiResponse
): selection is AbandonedCarSelectionApiResponse {
  return (
    'carURL' in selection &&
    isTripDateValid(selection.pickupDateTime, "yyyy-MM-dd'T'HH:mm:ss") &&
    !selection.isBooked
  )
}

function isValidAbandonedFlightSelection(
  selection: AbandonedSelectionApiResponse
): selection is AbandonedFlightSelectionApiResponse {
  return (
    'flightURL' in selection &&
    isTripDateValid(
      selection.originDestinationInfo.travelStartDateTime,
      "yyyy-MM-dd'T'HH:mm:ss"
    ) &&
    !selection.isBooked
  )
}

function getOriginDestAirportCodes(flightPath: AbandonedFlightPath[]) {
  return {
    departOriginAirportCode: flightPath[0].originAirportCode,
    departDestAirportCode: flightPath[0].destAirportCode,
    returnOriginAirportCode:
      flightPath[flightPath.length - 1].originAirportCode,
    returnDestAirportCode: flightPath[flightPath.length - 1].destAirportCode
  }
}

export function transformAbandonedFlightApiResponseToProps(
  item: AbandonedFlightSelectionApiResponse
) {
  const {
    travelStartDateTime,
    travelEndDateTime,
    originAirportInfo: {
      city: originCity = '',
      state: originState = '',
      country: originCountry,
      code: originCode
    },
    destinationAirportInfo: {
      city: destCity = '',
      state: destState = '',
      country: destCountry,
      code: destCode
    }
  } = item.originDestinationInfo
  const [startDate, endDate] = getDates({
    startDate: travelStartDateTime,
    endDate: travelEndDateTime
  })
  const [formattedStartDate, formattedEndDate] = formatDates({
    startDate,
    endDate,
    dateFormat: 'E, MMM dd, yyyy'
  })
  const {
    departOriginAirportCode,
    departDestAirportCode,
    returnOriginAirportCode,
    returnDestAirportCode
  } = getOriginDestAirportCodes(item.originDestinationInfo.flightPath)
  return {
    headline: `${originCity}, ${
      originState || originCountry
    } (${originCode}) - ${destCity}, ${destState || destCountry} (${destCode})`,
    linkUrl: item.flightURL,
    genericListingsUrl: item.genericListingsUrl,
    productType: 'flights' as const,
    tripType: item.tripType,
    dealType: item.dealType,
    departDate: formattedStartDate,
    returnDate: formattedEndDate,
    departOriginAirportCode,
    departDestAirportCode,
    returnOriginAirportCode,
    returnDestAirportCode,
    numAdults: item.numberOfAdults
  }
}

export function getFirstValidAbandonedSelection(
  selections: ReadonlyArray<AbandonedSelectionApiResponse>
): AbandonedSelection | null {
  const abandonedSelection =
    selections.find(selection => {
      if (isValidAbandonedHotelSelection(selection)) {
        return true
      }
      if (isValidAbandonedFlightSelection(selection)) {
        return true
      }

      return isValidAbandonedCarSelection(selection)
    }) ?? null

  if (abandonedSelection) {
    if ('hotelURL' in abandonedSelection) {
      return transformAbandonedHotelApiResponseToProps(abandonedSelection)
    }
    if ('flightURL' in abandonedSelection) {
      return transformAbandonedFlightApiResponseToProps(abandonedSelection)
    }

    return transformAbandonedCarApiResponseToProps(abandonedSelection)
  }

  return null
}

export function getPriceWatchProps(item: PriceWatchData) {
  const {
    originAbbreviation,
    originCityName,
    originStateName,
    destAbbreviation,
    destCityName,
    destStateName,
    dealPrice,
    savingsPercentage,
    departDate,
    returnDate,
    tripType,
    cabinClass
  } = item
  const [startDate, endDate] = getDates({
    startDate: departDate,
    endDate: returnDate
  })
  const [formattedStartDate, formattedEndDate] = formatDates({
    startDate,
    endDate
  })
  const mappedFlyCabinClass = FLY_CABIN_CLASS[cabinClass]
  return {
    icon: (
      <div style={{ lineHeight: '0px' }}>
        <FlightsFill size="24px" />
      </div>
    ),
    heading: `${originCityName}, ${originStateName} - ${destCityName}, ${destStateName}`,
    actionText: `${formattedStartDate} - ${formattedEndDate}`,
    relativeUrl: `/m/fly/search/${originAbbreviation}-${destAbbreviation}-${startDate}${
      tripType === 'ROUND_TRIP'
        ? `/${destAbbreviation}-${originAbbreviation}-${endDate}`
        : ``
    }/?cabin-class=${mappedFlyCabinClass}&no-date-search=false&num-adults=1&sbsroute=slice1&search-type=10`,
    priceWatch: {
      dealPrice,
      savingsPercentage
    }
  }
}

function buildPriceWatchSet(priceWatches: readonly PriceWatchData[]) {
  const mappedPriceWatches = priceWatches.map(item => {
    const { departDate, returnDate, originAbbreviation, destAbbreviation } =
      item
    return `${departDate} - ${returnDate} - ${originAbbreviation} - ${destAbbreviation}`
  })
  return new Set(mappedPriceWatches)
}

export function computePriceWatchAndRecentSearches(
  priceWatches: readonly PriceWatchData[],
  recentSearchData: readonly RecentTripSearches[]
): RecentSearchAndPriceWatchList {
  const priceWatchSet = buildPriceWatchSet(priceWatches)
  const filteredRecentSearches =
    priceWatches.length === 0
      ? recentSearchData
      : recentSearchData.filter(item => {
          if (item.productType === 'FLY') {
            const {
              travelStartDate,
              travelEndDate,
              originAirportInfo: { code: originCode },
              destinationAirportInfo: { code: destinationCode }
            } = item
            return !priceWatchSet.has(
              `${travelStartDate} - ${travelEndDate} - ${originCode} - ${destinationCode}`
            )
          }
          return true
        })
  const priceWatchesWithFlag = priceWatches.map(
    item =>
      ({
        ...item,
        isPriceWatch: true,
        productType: 'FLY'
      } as const)
  )
  return [...priceWatchesWithFlag, ...filteredRecentSearches]
}

function isTripISODateValid(tripDate: string) {
  const parseTripDate = parseISO(tripDate)
  return isToday(parseTripDate) || isAfter(parseTripDate, new Date())
}

export function getFirstValidTripGrouping(
  groupings: ReadonlyArray<TripGroupingApiResponse>
) {
  const tripGroup = groupings.find(grouping =>
    isTripISODateValid(grouping.bookingActivityData[0]?.travelStartDateTime)
  )

  // in case of multiple bookings, show first booking in group
  const tripGroupingBooking = tripGroup?.bookingActivityData?.[0] ?? null

  const tripGroupingAbandonedSelection = getFirstValidAbandonedSelection(
    tripGroup?.selectionActivityData ?? []
  )

  return { tripGroupingBooking, tripGroupingAbandonedSelection }
}

export function onCardClick(
  item:
    | SearchProductDataFiltered
    | RecentTripSearches
    | PriceWatchDataWithFlags,
  relativeUrl: string,
  analyticsCategory: 'PCLN_HOME' | 'HOTEL_HOME',
  productType: 'FLY' | 'STAY' | 'DRIVE'
) {
  fireRecentSearchCardClickEvent(item, productType, analyticsCategory)
  const url = new URL(relativeUrl, window.location.origin)
  window.open(url)
}

export function onViewAllButtonClick(analyticsCategory: string) {
  fireViewAllButtonClickEvent(analyticsCategory)
  window.open('/next-profile/trips?tab=recent_activity')
}
